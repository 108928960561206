/* @flow */

import { type CommonPromisedXMLHttpRequestReturnType, commonPromisedXMLHttpRequest } from '../../helpers/CommonPromisedXMLHttpRequest';
import { HTTP_READY_STATE_DONE, HttpStatus } from '../../v8/constants/NetworkCodesAndMessages';
import { XhrResponseType, getXhrResponseTypeFromString } from '../../../../helpers/jsHelpers/xhr';
import { CustomNetworkError } from '../../helpers/CustomNetworkError';
import { HttpMethod } from '../../v8/types/HttpMethod';
import { Localizer } from '@ntg/utils/dist/localization';
import type { NETGEM_API_V8_REQUEST_HEADERS } from '../../v8/types/Headers';
import { createCustomNetworkError } from '../../helpers/CreateCustomNetworkError';
import { getMessageFromErrorStatus } from '../constants/NetworkCodesAndMessages';
import { logInfo } from '../../../../helpers/debug/debug';

const sendDmsPromisedXMLHttpRequest: (
  url: string,
  method: HttpMethod,
  requestHeaderList: ?NETGEM_API_V8_REQUEST_HEADERS,
  bodyParam: ?string,
  requestParam: ?string,
  withCredentials: boolean,
  signal?: AbortSignal,
) => CommonPromisedXMLHttpRequestReturnType = (url, method, requestHeaderList, bodyParam, requestParam, withCredentials, signal) => {
  const dmsNetworkErrorCallBack = (request: XMLHttpRequest, message: ?string): CustomNetworkError => {
    const { readyState, response, responseType, status, statusText } = request;
    const responseHeaders = request.getAllResponseHeaders();

    // QA
    if (status !== HttpStatus.NotModified) {
      logInfo(`Error HTTP status: ${status}`);
    }

    if (status === 0 && readyState === HTTP_READY_STATE_DONE) {
      // Request miserably failed
      return createCustomNetworkError(HttpStatus.Error, message ?? Localizer.localize('common.messages.errors.request_failed'), responseHeaders, response);
    }

    // Request failed in a more appropriate way
    let msg: string | null = getMessageFromErrorStatus(status);

    if (!msg) {
      msg = statusText ?? (getXhrResponseTypeFromString(responseType) === XhrResponseType.Text ? request.responseText : '');
    }

    return createCustomNetworkError(status, message ?? msg, responseHeaders, response);
  };

  return commonPromisedXMLHttpRequest(url, method, XhrResponseType.Json, requestHeaderList, bodyParam, requestParam, withCredentials, dmsNetworkErrorCallBack, signal);
};

export default sendDmsPromisedXMLHttpRequest;
