/* @flow */

import '../ProgressBar.css';
import './LiveProgressBar.css';
import * as React from 'react';
import { getDurationDisplay, getTimestampDisplay, showDebug } from '../../../../../helpers/debug/debug';
import AccurateTimestamp from '../../../../../helpers/dateTime/AccurateTimestamp';
import { PictoDot } from '@ntg/components/dist/pictos/Element';
import clsx from 'clsx';
import { formatTimeFromSeconds } from '../../../../../helpers/dateTime/Format';
import { getBoundedValue } from '../../../../../helpers/maths/maths';

type LiveProgressBarPropType = {|
  +isTimeshiftEnabled: boolean,
  +liveBufferLength: number,
  +liveProgramEndTime: number,
  +liveProgramStartTime: number,
  +onSeekChanged: (time: number) => void,
  +playheadPosition: number,
|};

type LiveProgressBarStateType = {|
  canSeek: boolean,
  currentTimePosition: number,
  displayedTime: string,
  dragDelta: number,
  hoverPosition: number,
  hoverTime: number | null,
  isThumbDragged: boolean,
  // True when playhead has been dragged, and we're waiting for current seek position to be updated
  isWaitingForCurrentPositionUpdate: boolean,
  percentAvailable: number,
  percentProgress: number,
  percentStartProgressBar: number,
|};

const InitialState = Object.freeze({
  canSeek: false,
  currentTimePosition: 0,
  displayedTime: '',
  dragDelta: 0,
  hoverPosition: 0,
  hoverTime: null,
  isThumbDragged: false,
  isWaitingForCurrentPositionUpdate: false,
  percentAvailable: 0,
  percentProgress: 0,
  percentStartProgressBar: 0,
});

// When timeshift is disabled, hovering the progress bar at now -/+ LIVE_THRESHOLD displays the time badge (in seconds)
const LIVE_THRESHOLD = 4;

// When live player is paused, time is updated every second
const LIVE_TIME_UPDATE_TIMEOUT = 1_000;

const ONE_HUNDRED = 100;

class LiveProgressBar extends React.PureComponent<LiveProgressBarPropType, LiveProgressBarStateType> {
  initialSeekPosition: number;

  initialTimeStamp: number;

  liveTimeUpdateInterval: IntervalID | null;

  mainContainer: HTMLElement | null;

  programDuration: number;

  programEnd: number;

  programStart: number;

  constructor(props: LiveProgressBarPropType) {
    super(props);

    const { isTimeshiftEnabled, liveBufferLength } = props;

    this.initialSeekPosition = 0;
    this.initialTimeStamp = 0;
    this.liveTimeUpdateInterval = null;
    this.mainContainer = null;
    this.programDuration = 0;
    this.programEnd = 0;
    this.programStart = 0;

    this.state = { ...InitialState };

    this.state = {
      ...InitialState,
      canSeek: isTimeshiftEnabled && liveBufferLength > 0,
    };
  }

  componentDidMount() {
    this.initialize();
  }

  componentDidUpdate(prevProps: LiveProgressBarPropType) {
    const { isTimeshiftEnabled, liveBufferLength, playheadPosition } = this.props;
    const { isTimeshiftEnabled: prevIsTimeshiftEnabled, liveBufferLength: prevLiveBufferLength, playheadPosition: prevPlayheadPosition } = prevProps;

    this.updateProgressBar();

    if (playheadPosition === prevPlayheadPosition && liveBufferLength === prevLiveBufferLength) {
      return;
    }

    if (isTimeshiftEnabled !== prevIsTimeshiftEnabled || liveBufferLength !== prevLiveBufferLength) {
      this.updateCanSeek(liveBufferLength);
    }

    if (playheadPosition !== prevPlayheadPosition) {
      if (prevPlayheadPosition <= 0) {
        this.initialize();
      }

      this.onPlayheadChanged();
    }
  }

  componentWillUnmount() {
    this.resetLiveTimeUpdateInterval();

    window.removeEventListener('mousemove', this.handleThumbMouseMove, { capture: true });
    window.removeEventListener('mouseup', this.handleThumbMouseUp, { capture: true });
  }

  showDebugInfo = () => {
    const {
      initialSeekPosition,
      initialTimeStamp,
      programDuration,
      programEnd,
      programStart,
      props: { liveBufferLength, liveProgramEndTime, liveProgramStartTime, playheadPosition },
      state,
      state: { currentTimePosition, hoverTime },
    } = this;

    showDebug('Standard progress bar', {
      misc: {
        currentTimePosition: getTimestampDisplay(currentTimePosition),
        hoverTime: getDurationDisplay(hoverTime),
        initialSeekPosition: getTimestampDisplay(initialSeekPosition),
        initialTimeStamp: getTimestampDisplay(initialTimeStamp),
        liveBufferLength: getDurationDisplay(liveBufferLength),
        liveProgramEndTime: getTimestampDisplay(liveProgramEndTime),
        liveProgramStartTime: getTimestampDisplay(liveProgramStartTime),
        playheadPosition: getTimestampDisplay(playheadPosition),
        programDuration: getDurationDisplay(programDuration),
        programEnd: getTimestampDisplay(programEnd),
        programStart: getTimestampDisplay(programStart),
      },
      state,
      stateFields: ['canSeek', 'displayedTime', 'dragDelta', 'hoverPosition', 'isThumbDragged', 'isWaitingForCurrentPositionUpdate', 'percentAvailable', 'percentProgress', 'percentStartProgressBar'],
    });
  };

  onPlayheadChanged = () => {
    this.setState({ isWaitingForCurrentPositionUpdate: false });
  };

  initialize = () => {
    const { playheadPosition } = this.props;

    if (playheadPosition > 0) {
      // Player initialized
      this.initialTimeStamp = AccurateTimestamp.nowInSeconds();
      this.initialSeekPosition = playheadPosition;
    } else {
      // Player not initialized yet
      this.initialTimeStamp = 0;
      this.initialSeekPosition = 0;
      this.programDuration = 0;
      this.programStart = 0;
      this.programEnd = 0;

      this.setState({ percentProgress: 0 });
    }

    if (this.liveTimeUpdateInterval === null) {
      this.liveTimeUpdateInterval = setInterval(this.updateLiveTime, LIVE_TIME_UPDATE_TIMEOUT);
    }
  };

  resetLiveTimeUpdateInterval: () => void = () => {
    if (this.liveTimeUpdateInterval) {
      clearInterval(this.liveTimeUpdateInterval);
      this.liveTimeUpdateInterval = null;
    }
  };

  updateLiveTime: () => void = () => {
    const { initialSeekPosition, initialTimeStamp } = this;

    const delta = AccurateTimestamp.nowInSeconds() - initialTimeStamp;
    this.setState({ currentTimePosition: initialSeekPosition + delta });
  };

  updateCanSeek = (liveBufferLength: number) => {
    const { isTimeshiftEnabled } = this.props;

    this.setState({ canSeek: isTimeshiftEnabled && liveBufferLength > 0 });
  };

  updateProgressBar = () => {
    const { liveBufferLength, playheadPosition } = this.props;
    const { canSeek, isWaitingForCurrentPositionUpdate, isThumbDragged, currentTimePosition } = this.state;
    const { programDuration, programStart } = this;

    if (isWaitingForCurrentPositionUpdate) {
      // Skip calculation until playheadPosition is updated (avoid playhead jumps after dragging it)
      return;
    }

    if (isThumbDragged) {
      this.updateAvailableBar();
      return;
    }

    let percentProgress = getBoundedValue(((playheadPosition - programStart) * ONE_HUNDRED) / programDuration);
    let percentStartProgressBar = 0;

    if (canSeek) {
      // Progress bar start will be greater than 0 when the elapsed duration of the program is greater than the timeshift buffer depth
      const leftOffset = currentTimePosition - liveBufferLength - programStart;
      percentStartProgressBar = leftOffset > 0 ? (leftOffset * ONE_HUNDRED) / programDuration : 0;

      percentProgress -= Math.max(0, percentStartProgressBar);
    } else {
      // Timeshift is not available: display simple progress bar
      percentStartProgressBar = 0;
    }

    this.setState(
      {
        percentProgress,
        percentStartProgressBar,
      },
      () => this.updateAvailableBar(),
    );
  };

  updateAvailableBar = () => {
    const { liveBufferLength, liveProgramEndTime, liveProgramStartTime } = this.props;
    const { currentTimePosition, percentStartProgressBar } = this.state;
    const { initialSeekPosition, initialTimeStamp } = this;

    let percentAvailable = 0;

    if (liveProgramStartTime && liveProgramEndTime) {
      // Start and end of program are known
      this.programDuration = liveProgramEndTime - liveProgramStartTime;
      this.programStart = initialSeekPosition + (liveProgramStartTime - initialTimeStamp);
      this.programEnd = initialSeekPosition + (liveProgramEndTime - initialTimeStamp);

      if (currentTimePosition > this.programEnd) {
        // Ensure we don't overshoot
        percentAvailable = ONE_HUNDRED;
      } else {
        percentAvailable = ((currentTimePosition - this.programStart) * ONE_HUNDRED) / this.programDuration;

        if (this.programStart < currentTimePosition - liveBufferLength) {
          // Program started outside the live buffer window
          percentAvailable -= percentStartProgressBar;
        }

        if (percentStartProgressBar + percentAvailable > ONE_HUNDRED) {
          // Calculation error: let's reduce it a bit
          percentAvailable = ONE_HUNDRED - percentStartProgressBar;
        }
      }
    } else {
      // Start and end of program are unknown
      this.programDuration = liveBufferLength;
      this.programEnd = currentTimePosition;
      this.programStart = this.programEnd - liveBufferLength;
      percentAvailable = ONE_HUNDRED;
    }

    this.setState({ percentAvailable });
  };

  // Given value is the difference between wanted position and current position (negative value means going backward, positive means forward)
  seekTo = (value: number) => {
    const { onSeekChanged } = this.props;

    onSeekChanged(value);
  };

  // Returned value 'hoverTime' is the time relative to the start of the program
  getHoverTimeFromMousePosition = (clientX: number): {| hoverTime: number, mousePosition: number |} => {
    const { mainContainer } = this;

    let hoverTime = 0;
    let mousePosition = 0;

    if (mainContainer) {
      const { left } = mainContainer.getBoundingClientRect();
      mousePosition = clientX - left;
      const { offsetWidth } = mainContainer;
      hoverTime = (this.programDuration * mousePosition) / offsetWidth;
    }

    return {
      hoverTime,
      mousePosition,
    };
  };

  handleMainContainerOnClick = (event: SyntheticMouseEvent<HTMLElement>): void => {
    const { playheadPosition } = this.props;
    const { canSeek, hoverTime, isWaitingForCurrentPositionUpdate } = this.state;
    const { programStart } = this;
    const { altKey, ctrlKey } = event;

    if (ctrlKey || altKey) {
      this.showDebugInfo();
      return;
    }

    if (!canSeek || isWaitingForCurrentPositionUpdate) {
      return;
    }

    if (hoverTime) {
      const delta = hoverTime - playheadPosition + programStart;
      this.seekTo(delta);
    }
  };

  handleMouseMove = (event: SyntheticMouseEvent<HTMLElement>) => {
    const { liveBufferLength, liveProgramStartTime } = this.props;
    const { canSeek } = this.state;
    const { clientX } = event;

    const { hoverTime, mousePosition } = this.getHoverTimeFromMousePosition(clientX);

    const absoluteHoverTime = liveProgramStartTime + hoverTime;

    // Difference between live time and time where mouse cursor is
    const liveDelta = absoluteHoverTime - AccurateTimestamp.nowInSeconds();

    // If timeshift is enabled, the time badge is displayed when the mouse cursor is between live position and timeshift buffer start, otherwise a short span is used
    const liveTimeRef = canSeek ? liveBufferLength : LIVE_THRESHOLD;

    if (-liveTimeRef <= liveDelta && liveDelta <= LIVE_THRESHOLD) {
      // Mouse cursor is over a seekable point
      this.displayTimeBadge(mousePosition, hoverTime);
    } else {
      this.handleMouseLeave();
    }
  };

  displayTimeBadge = (mousePosition: number, hoverTime: number) => {
    const { liveProgramStartTime } = this.props;

    this.setState({
      displayedTime: formatTimeFromSeconds(liveProgramStartTime + hoverTime),
      hoverPosition: mousePosition,
      hoverTime,
    });
  };

  handleMouseLeave = () => {
    this.setState({
      displayedTime: '',
      hoverPosition: 0,
      hoverTime: null,
    });
  };

  // Start playhead dragging
  handleThumbMouseDown = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    const { canSeek } = this.state;

    if (!canSeek) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    this.setState({ isThumbDragged: true });

    window.addEventListener('mousemove', this.handleThumbMouseMove, { capture: true });
    window.addEventListener('mouseup', this.handleThumbMouseUp, { capture: true });
  };

  handleThumbMouseMove = (event: SyntheticMouseEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const { liveProgramStartTime, playheadPosition } = this.props;
    const { isThumbDragged, percentStartProgressBar } = this.state;
    const { programDuration, programStart } = this;
    const { clientX } = event;

    if (!isThumbDragged) {
      // Shouldn't be possible since handleThumbMouseMove is not defined when isThumbDragged is false, but what do we know?
      return;
    }

    const { hoverTime, mousePosition } = this.getHoverTimeFromMousePosition(clientX);
    const absoluteHoverTime = liveProgramStartTime + hoverTime;

    if (absoluteHoverTime > AccurateTimestamp.nowInSeconds()) {
      // We cannot timeshift in the future (or can we?)
      return;
    }

    const dragDelta = hoverTime - playheadPosition + programStart;
    const percentProgress = getBoundedValue((hoverTime * ONE_HUNDRED) / programDuration - percentStartProgressBar);

    this.setState({
      dragDelta,
      percentProgress,
    });

    this.displayTimeBadge(mousePosition, hoverTime);
  };

  // Stop playhead dragging
  handleThumbMouseUp = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.performSeek();

    this.setState({
      dragDelta: 0,
      isThumbDragged: false,
      isWaitingForCurrentPositionUpdate: true,
    });

    window.removeEventListener('mousemove', this.handleThumbMouseMove, { capture: true });
    window.removeEventListener('mouseup', this.handleThumbMouseUp, { capture: true });
  };

  performSeek = () => {
    const { dragDelta } = this.state;

    this.seekTo(dragDelta);
  };

  renderAvailableBar = (): React.Node => {
    const { percentAvailable, percentStartProgressBar } = this.state;

    return (
      <div
        className='available'
        style={{
          left: `${percentStartProgressBar}%`,
          width: `${percentAvailable}%`,
        }}
      />
    );
  };

  renderProgressBar = (): React.Node => {
    const { canSeek, isThumbDragged, percentProgress, percentStartProgressBar } = this.state;

    return (
      <div
        className='progress'
        style={{
          left: `${percentStartProgressBar}%`,
          width: `${percentProgress}%`,
        }}
      >
        <PictoDot className={clsx('thumb', isThumbDragged && 'dragged')} draggable={canSeek} onMouseDown={this.handleThumbMouseDown} />
      </div>
    );
  };

  renderTimeBadge = (): React.Node => {
    const { hoverPosition, displayedTime } = this.state;

    if (displayedTime === '') {
      return null;
    }

    return (
      <div className='timeBadge' draggable={false} style={{ transform: `translateX(calc(${hoverPosition}px - 50%))` }}>
        {displayedTime}
      </div>
    );
  };

  render(): React.Node {
    const { hoverTime } = this.state;

    return (
      <div className='progressBar live'>
        <div className='reactiveBackground'>
          <div
            className={clsx('mainContainer', hoverTime ? 'hoveredGood' : 'hoveredBad')}
            onClick={this.handleMainContainerOnClick}
            onMouseLeave={this.handleMouseLeave}
            onMouseMove={this.handleMouseMove}
            ref={(instance) => {
              this.mainContainer = instance;
            }}
          >
            {this.renderAvailableBar()}
            {this.renderProgressBar()}
            {this.renderTimeBadge()}
          </div>
        </div>
      </div>
    );
  }
}

export default (LiveProgressBar: React.ComponentType<LiveProgressBarPropType>);
