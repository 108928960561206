/* @flow */

import { BO_API_CREATE_STREAM2, type BO_RESPONSE_WITH_ETAG } from './types/common';
import type { BO_API_STREAM_TYPE, BO_API_TICKET_TYPE } from './types/stream';
import { Drm, getDrmName, getFirstSupportedDrm } from '../../../../helpers/jsHelpers/Drm';
import type { NetgemApiEmitterType, RequestResponseMethodDefinitionType } from '../emitter';
import type { BO_API_PARAMETERS_TYPE } from '../../../../helpers/videofutur/queryParameters';
import type { CombinedReducers } from '../../../reducers';
import type { Dispatch } from '../../../types/types';
import { REDUX_MSG_REQUEST_BO_STREAM_CREATE } from '../../constants';
import { StreamType } from '../../../../libs/netgemLibrary/v8/types/MetadataSchedule';
import { convertSubtitleTrackMetadataToExternalSubtitles } from './helpers/subtitles';
import { getIso8601DurationInSeconds } from '../../../../helpers/dateTime/Format';
import getRequestDetails from './helpers/requestCommon';

const sendCreateStreamRequest: (distributorId: string, vtiId: number, license: boolean, signal?: AbortSignal) => RequestResponseMethodDefinitionType =
  (distributorId, vtiId, license, signal) =>
  (dispatch: Dispatch, getState: () => CombinedReducers, NetgemApiEmitter: NetgemApiEmitterType): Promise<any> => {
    const {
      appConfiguration: { useBOV2Api },
    } = getState();

    const params: BO_API_PARAMETERS_TYPE = {
      license,
      vtiId,
    };

    if (license) {
      const drm = getFirstSupportedDrm();
      params.drm = getDrmName(drm);
      params.format = drm === Drm.Fairplay ? (StreamType.HLS: string) : (StreamType.DASH: string);
    }

    return dispatch(getRequestDetails(distributorId, BO_API_CREATE_STREAM2, useBOV2Api))
      .then((requestDetails) =>
        // Object 'requestDetails' contains 'headers', 'method' and 'uri' parameters
        NetgemApiEmitter.emit(REDUX_MSG_REQUEST_BO_STREAM_CREATE, {
          ...requestDetails,
          params,
          signal,
          useBOV2Api,
        }),
      )
      .then(({ response }: BO_RESPONSE_WITH_ETAG) => {
        if (useBOV2Api) {
          const {
            body: {
              data: { grantedTicket, lastBookmark, subtitleTrackMetadatas, type: streamType, vstId },
            },
          } = response;

          const stream: BO_API_STREAM_TYPE = {
            appId: '',
            id: vstId,
            lastBookmark: getIso8601DurationInSeconds(lastBookmark),
            subtitles: convertSubtitleTrackMetadataToExternalSubtitles(subtitleTrackMetadatas),
            type: streamType,
            vtiId,
          };

          if (grantedTicket !== undefined) {
            const gt: BO_API_TICKET_TYPE = {
              contentUrl: grantedTicket.contentUrl,
              fairplayCertificateUrl: grantedTicket.fairplayCertificateUrl,
              fairplayContentKeySystem: grantedTicket.fairplayContentKeySystem,
              laUrl: grantedTicket.laUrl,
              licenceIssuer: grantedTicket.licenseIssuer,
              status: '',
            };

            const { heartbeat, type } = grantedTicket.urlLifecycle;
            if (typeof heartbeat === 'string' && typeof type === 'string') {
              gt.urlLifecycle = grantedTicket.urlLifecycle;
            }

            stream.grantedTicket = gt;
          }

          return { stream };
        }

        const { stream } = response;
        return {
          stream: {
            ...stream,
            subtitles: [],
          },
        };
      });
  };

export default sendCreateStreamRequest;
