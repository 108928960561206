/* @flow */

import * as React from 'react';
import type { AllTracks, AudioTrack, ShakaOfflineProgressUpdate, ShakaTrack, TextTrack, VideoTrack } from './shakaTypes';
import { HeightKind, WidthKind } from '../../buttons/types';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import ButtonFX from '../../buttons/ButtonFX';
import ShakaStorage from './shakaStorage';

const cloneAudioTracks: (tracks: Array<AudioTrack>) => Array<AudioTrack> = (tracks) =>
  tracks.map((track) => {
    return {
      ...track,
      roles: [...track.roles],
    };
  });

const cloneTextTracks: (tracks: Array<TextTrack>) => Array<TextTrack> = (tracks) =>
  tracks.map((track) => {
    return {
      ...track,
      roles: [...track.roles],
    };
  });

const cloneVideoTracks: (tracks: Array<VideoTrack>) => Array<VideoTrack> = (tracks) =>
  tracks.map((track) => {
    return {
      ...track,
      roles: [...track.roles],
    };
  });

// TODO: use cache
const cloneAllTracks: (tracks: AllTracks) => AllTracks = (tracks) => {
  const { audio, text, video } = tracks;

  return {
    audio: cloneAudioTracks(audio),
    text: cloneTextTracks(text),
    video: cloneVideoTracks(video),
  };
};

const handleCancelOnClick: (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>, id: string) => void = (event, id) => {
  if (id) {
    ShakaStorage.cancelDownload(id);
  }
};

// Sending progress of 1 will immediately close the toast, whatever the value of autoClose
const ALMOST_ONE = 0.99;
const generateUpdateDownloadProgress: (toastId: string, title: string) => ShakaOfflineProgressUpdate =
  (toastId, title) =>
  (content: any, progress: number): void => {
    if (progress === -1) {
      // Error
      Messenger.emit(MessengerEvents.NOTIFY_ERROR, <div>Erreur lors du téléchargement de &#xAB;&nbsp;{title}&nbsp;&#xBB;</div>, { progress, toastId });
      Messenger.emit(MessengerEvents.NOTIFY_CLOSE, toastId);
    } else if (progress < 1) {
      // Downloading
      Messenger.emit(
        MessengerEvents.NOTIFY_INFO,
        <>
          <div>Téléchargement de &#xAB;&nbsp;{title}&nbsp;&#xBB;...</div>
          <ButtonFX data={toastId} heightKind={HeightKind.Small} onClick={handleCancelOnClick} widthKind={WidthKind.Stretched}>
            Annuler
          </ButtonFX>
        </>,
        { progress, toastId },
      );
    } else {
      // Finished
      Messenger.emit(MessengerEvents.NOTIFY_SUCCESS, <div>&#xAB;&nbsp;{title}&nbsp;&#xBB; a été téléchargé</div>, { progress: ALMOST_ONE, toastId });
      Messenger.emit(MessengerEvents.NOTIFY_CLOSE, toastId);
      Messenger.emit(MessengerEvents.DOWNLOAD_SUCCESSFUL);
    }
  };

const selectDownloadTracks: (tracks: Array<ShakaTrack>) => Array<ShakaTrack> = (tracks) => {
  let index = -1;
  let maxBandwidth = -1;
  for (let i = 0; i < tracks.length; ++i) {
    const {
      [i]: { bandwidth, type },
    } = tracks;
    if (type === 'variant' && bandwidth > maxBandwidth) {
      index = i;
      maxBandwidth = bandwidth;
    }
  }

  if (index > -1) {
    // Track with the highest bandwidth found
    return [tracks[index]];
  }

  // First variant track selected
  const firstTrack = tracks.find(({ type }) => type === 'variant');
  if (firstTrack) {
    return [firstTrack];
  }

  // No track
  return [];
};

const getContentId: (skdOriginalValue: string, fairplayContentKeySystem?: string) => string = (skdOriginalValue, fairplayContentKeySystem) => {
  if (fairplayContentKeySystem !== 'verimatrix') {
    // Regular case: use SKD as it is
    return skdOriginalValue;
  }

  // Verimatrix case: extract SKD

  // [Case 1] request id: skd://e670fd8208733965b03cfd818d21e3fc → content key id: e670fd8208733965b03cfd818d21e3fc
  const ma1 = skdOriginalValue.match(/\/([a-z\d]+)$/iu);
  if (ma1) {
    return ma1[1];
  }

  // [Case 2] request id: skd://fps.ezdrm.com/;d9fb2bc6-6e03-4206-abfe-665405e083cc → content key id: d9fb2bc66e034206abfe665405e083cc
  const ma2 = skdOriginalValue.match(/;([a-z\d]{8})-([a-z\d]{4})-([a-z\d]{4})-([a-z\d]{4})-([a-z\d]{12})$/iu);
  if (ma2) {
    // eslint-disable-next-line no-magic-numbers
    return ma2.slice(1, 6).join('');
  }

  // None of the 2 known forms was recognized: let's return original SKD
  return skdOriginalValue;
};

export { cloneAllTracks, getContentId, selectDownloadTracks, generateUpdateDownloadProgress };
