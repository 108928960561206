/* @flow */

import './Player.css';
import * as React from 'react';
import {
  BLINKING_PICTO_DURATION,
  COVER_IMAGE_HEIGHT,
  COVER_IMAGE_WIDTH,
  type CloseData,
  type CompletePlayerPropType,
  ContentType,
  DEBUG_INFO_METRICS_TIMEOUT,
  DOUBLE_CLICK,
  type ErrorCallback,
  GMC_IMAGE_HEIGHT,
  GMC_IMAGE_WIDTH,
  NORMAL_PLAYBACK_INDEX,
  NO_ERROR_CODE,
  type NTGENTITLEMENT_PARAMS,
  OVERLAY_TIMEOUT,
  PLAYBACK_DURATION_ERROR_THRESHOLD,
  PLAYBACK_RATES,
  PLAYHEAD_POSITION_CHANGE_THRESHOLD,
  type PlayerPropType,
  type PlayerStateInitializationType,
  type PlayerStateType,
  ProgramInfoLoadStatus,
  RESOLUTION,
  RESUME_REWIND_DURATION,
  type ReduxPlayerDispatchToPropsType,
  type ReduxPlayerReducerStateType,
  type ResumeData,
  ResumeState,
  SkippingKind,
  StopStreamKind,
  TIMESHIFT_THRESHOLD,
  VOLUME_STEP,
  type ViewingHistoryIds,
} from './constantsAndTypes';
import { type BO_API_CREATE_STREAM_TYPE, type BO_API_STREAM_TYPE } from '../../redux/netgemApi/actions/videofutur/types/stream';
import {
  BO_INVALID_APPLICATION_V2,
  BO_INVALID_JWT_V2,
  BO_INVALID_SUBSCRIBER_V2,
  BO_STREAM_DEVICE_NOT_FOUND,
  BO_STREAM_INVALID_USER_IP,
  BO_STREAM_MAX_CONCURRENT_STREAMS_REACHED,
  BO_STREAM_NO_SVOD_CONTENT_RIGHT,
} from '../../libs/netgemLibrary/videofutur/types/ErrorCodes';
import type { BasicFunction, Undefined } from '@ntg/utils/dist/types';
import { CHANNEL_IMAGE_HEIGHT, CHANNEL_IMAGE_WIDTH, VOD_TILE_HEIGHT, VOD_TILE_WIDTH } from '../../helpers/ui/constants';
import { type CONFIRMATION_DATA_MODAL_TYPE, ConfirmationModalResult } from '../modal/confirmationModal/ConfirmationModal';
import { ControlLevel, eventToControlLevel, getSkippingTypeAndValue } from '../../helpers/ui/player';
import { type DATA_COLLECTION_INTERNAL_MESSAGE, DataCollectionMessage, DataCollectionPlayerState, DataCollectionStream } from '../../libs/netgemLibrary/v8/types/DataCollection';
import { Drm, getFirstSupportedDrm } from '../../helpers/jsHelpers/Drm';
import { ExtendedItemType, FAKE_EPG_LIVE_PREFIX, FAKE_LIVE_CHANNEL_PREFIX } from '../../helpers/ui/item/types';
import { HttpStatus, NetgemNetworkCode } from '../../libs/netgemLibrary/v8/constants/NetworkCodesAndMessages';
import KeepAlive, { KeepAliveState } from './keepAlive';
import { Luminosity, type LuminosityType } from '@ntg/ui/dist/theme';
import {
  METADATA_KIND_PROGRAM,
  METADATA_KIND_SERIES,
  type MetadataKind,
  type NETGEM_API_V8_METADATA,
  type NETGEM_API_V8_METADATA_PROGRAM,
  type NETGEM_API_V8_METADATA_SERIES,
} from '../../libs/netgemLibrary/v8/types/MetadataProgram';
import { METRICS_ESTAT, MetricsStreamType, type NETGEM_API_CHANNEL } from '../../libs/netgemLibrary/v8/types/Channel';
import { MILLISECONDS_PER_SECOND, formatDuration, getIso8601DateInSeconds, getIso8601DurationInSeconds } from '../../helpers/dateTime/Format';
import { Messenger, MessengerEvents } from '@ntg/utils/dist/messenger';
import type { NETGEM_API_ENTITLEMENT_RESULT, NETGEM_API_URL_LIFECYCLE } from '../../libs/netgemLibrary/ntgEntitlement/types';
import {
  type NETGEM_API_V8_FEED_ITEM,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_EST,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD,
  NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD,
} from '../../libs/netgemLibrary/v8/types/FeedItem';
import {
  type NETGEM_API_V8_METADATA_LOCATION_VIDEO_STREAM_PARAM,
  type NETGEM_API_V8_METADATA_SCHEDULE_LOCATION,
  type NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_ENTITLEMENT_PARAM,
  type NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_PARAM,
  StreamType,
} from '../../libs/netgemLibrary/v8/types/MetadataSchedule';
import { PictoBigPlay, PictoForward10, PictoForward30, PictoForward60, PictoPause, PictoRewind10, PictoRewind30, PictoRewind60 } from '@ntg/components/dist/pictos/Element';
import { SentryTagName, SentryTagValue } from '../../helpers/debug/sentryTypes';
import { type ShakaOfflineContent, getShakaErrorCategoryAsText, getShakaErrorCodeAsText, shouldErrorBeIgnored } from './implementation/shakaTypes';
import { VIDEOPLAYER_DEBUG, VIDEOPLAYER_ERRORS, type VideoPlayerInitData, type VideoPlayerMediaInfo, VideoPlayerMediaType } from './implementation/types';
import { WebAppHelpersLocationStatus, getLocationStatus } from '../../helpers/ui/location/Format';
import { channelHasTimeshift, getChannelImageId, getChannelMetrics, getChannelUrlLifecycle } from '../../helpers/channel/helper';
import { filterExternalSubtitles, getHtmlMediaErrorText, getInitDataAsSentryContext, getStreamsFromPlaybackUrls, getVideoStreamDataFromChannel, isInvalidTokenError } from './helper';
import { formatSeasonEpisodeNbr, getTitle } from '../../helpers/ui/metadata/Format';
import { getAudioSettingCode, getSubtitlesSettingCode, getSubtitlesTrackFromCode } from '../../helpers/ui/language';
import { getDurationDisplay, getTimeRangesAsString, getTimestampDisplay, logDebug, logError, logWarning, showDebug } from '../../helpers/debug/debug';
import { getTrailer, hasBeenPurchased } from '../../helpers/videofutur/metadata';
import { hideModal, showConfirmationModal } from '../../redux/modal/actions';
import AccurateTimestamp from '../../helpers/dateTime/AccurateTimestamp';
import { AppMobileAppLogo } from '../../helpers/applicationCustomization/ui';
import type { BO_API_ERROR_TYPE } from '../../redux/netgemApi/actions/videofutur/types/common';
import ButtonBack from '../buttons/ButtonBack';
import Collector from '../../helpers/dataCollection/collector';
import type { CombinedReducers } from '../../redux/reducers';
import { CustomNetworkError } from '../../libs/netgemLibrary/helpers/CustomNetworkError';
import type { DATA_COLLECTION_MESSAGE_SETTINGS } from '../../helpers/dataCollection/types';
import { Definition } from '../../helpers/ui/metadata/Types';
import type { Dispatch } from '../../redux/types/types';
import EpgManager from '../../helpers/epg/epgManager';
import HotKeys from '../../helpers/hotKeys/hotKeys';
import InfiniteCircleLoaderArc from '../loader/infiniteCircleLoaderArc';
import { LoadableStatus } from '../../helpers/loadable/loadable';
import { Localizer } from '@ntg/utils/dist/localization';
import MediaController from '../../helpers/mediaSession/mediaController';
import Mediametrie from '../../helpers/mediametrie/Mediametrie';
import { type NETGEM_API_V8_AUTHENT_REALM } from '../../libs/netgemLibrary/v8/types/Realm';
import type { NETGEM_API_V8_URL_DEFINITION } from '../../libs/netgemLibrary/v8/types/NtgVideoFeed';
import { type NETGEM_API_VIEWINGHISTORY_ITEM } from '../../libs/netgemLibrary/v8/types/ViewingHistory';
import { PlayerError } from '../../helpers/playerError/PlayerError';
import PlayerShaka from './implementation/playerShaka';
import { RecordingOutcome } from '../../libs/netgemLibrary/v8/types/Npvr';
import { RegistrationType } from '../../redux/appRegistration/types/types';
import SentryWrapper from '../../helpers/debug/sentry';
import { Setting } from '../../helpers/settings/types';
import { type SettingValueType } from '../settings/SettingsConstsAndTypes';
import ShakaStorage from './implementation/shakaStorage';
import { SkipDirection } from '../../helpers/ui/types';
import { TileConfigTileType } from '../../libs/netgemLibrary/v8/types/WidgetConfig';
import VideoController from './controller/PlayerController';
import { buildErrorResponse as buildBOErrorResponse } from '../../redux/netgemApi/actions/helpers/bo';
import { buildViewingHistoryItem } from '../../helpers/viewingHistory/ViewingHistory';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { fireAndForget } from '../../helpers/jsHelpers/promise';
import fscreen from 'fscreen';
import { generateApiUrl } from '../../redux/netgemApi/actions/helpers/api';
import { getBoundedValue } from '../../helpers/maths/maths';
import { getCommonMetrics } from './implementation/metrics';
import { getImageUrl } from '../../redux/netgemApi/actions/v8/metadataImage';
import { getLocationType } from '../../libs/netgemLibrary/v8/helpers/Item';
import { getRoundedDurationToISOString } from '../../libs/netgemLibrary/v8/helpers/Date';
import { getSettingValueByNameFromDeviceSettings } from '../../redux/netgemApi/actions/helpers/settings';
import { ignoreIfAborted } from '../../libs/netgemLibrary/helpers/cancellablePromise/promiseHelper';
import { isRecordingMatching } from '../../helpers/npvr/recording';
import { parseBoolean } from '../../helpers/jsHelpers/parser';
import { produce } from 'immer';
import { purchaseAndCreate } from '../../redux/netgemApi/actions/videofutur/helpers/purchase';
import { renderDebugOverlay } from './debug';
import sendBOStreamCreateRequest from '../../redux/netgemApi/actions/videofutur/createStream';
import sendBOStreamStartRequest from '../../redux/netgemApi/actions/videofutur/streamStart';
import sendBOStreamStopRequest from '../../redux/netgemApi/actions/videofutur/streamStop';
import sendNtgEntitlementGetRequest from '../../redux/netgemApi/actions/ntgEntitlement/get';
import sendNtgEntitlementReleaseRequest from '../../redux/netgemApi/actions/ntgEntitlement/release';
import sendV8LocationEpgRequest from '../../redux/netgemApi/actions/v8/epg';
import sendV8MetadataLocationRequest from '../../redux/netgemApi/actions/v8/metadataLocation';
import sendV8MetadataRequest from '../../redux/netgemApi/actions/v8/metadata';
import { sendV8RecordingsMetadataRequest } from '../../redux/netgemApi/actions/v8/recordings';
import { stopOpenStreams } from '../../redux/netgemApi/actions/videofutur/helpers/stopOpenStreams';
import { updateSetting } from '../../redux/ui/actions';
import { updateViewingHistory } from '../../redux/netgemApi/actions/personalData/viewingHistory';

const InitialState: PlayerStateType = {
  audioMediaInfo: [],
  bufferedTimeRanges: null,
  channel: null,
  channelImageUrl: '',
  contentType: ContentType.Static,
  controlLevel: ControlLevel.Level0,
  currentItem: null,
  dataCollectionLastPlaybackTime: 0,
  dataCollectionMessage: null,
  dataCollectionStartTime: 0,
  debugInfo: {
    isPlayheadPositionFallback: false,
    laUrl: '',
    playerName: '',
    playerVersion: '',
    state: VIDEOPLAYER_DEBUG.StateInitializing,
    streamUrl: '',
    time: 0,
    totalTime: 0,
  },
  downloadOperationId: null,
  duration: 0,
  endMargin: 0,
  externalSubtitles: [],
  forcedResumePosition: null,
  imageUrl: null,
  isBOStreamRequestPending: false,
  isBuffering: true,
  isControllerEnabled: false,
  isCurrentItemBlackedOut: false,
  isDebugOverlayVisible: false,
  isInFullscreen: false,
  isLiveRecording: false,
  isMuted: false,
  isOverlayVisible: false,
  isPlaying: false,
  isTimeshiftEnabled: false,
  isTimeshiftSwitching: false,
  isTrailer: false,
  isVideoQualityAuto: false,
  isVideofuturAsset: false,
  liveBufferLength: 0,
  location: null,
  locationId: null,
  locationMetrics: null,
  locationStatus: null,
  playheadPosition: 0,
  programInfoLoadStatus: ProgramInfoLoadStatus.NotStarted,
  programMetadata: null,
  realEnd: 0,
  realStart: 0,
  resumePosition: -1,
  resumeState: ResumeState.NotChecked,
  selectedAudioMediaInfo: 0,
  selectedSubtitlesMediaInfo: 0,
  selectedVideoQuality: 0,
  seriesEpisodeText: null,
  seriesMetadata: null,
  skipping: SkippingKind.None,
  startMargin: 0,
  subtitlesMediaInfo: [],
  timeshift: 0,
  title: '',
  totalDuration: 0,
  userViewEndOffset: 0,
  userViewStartOffset: 0,
  videoStreamData: null,
  viewingHistoryId: null,
  volume: 0,
  vtiId: null,
};

class PlayerView extends React.PureComponent<CompletePlayerPropType, PlayerStateType> {
  abortController: AbortController;

  checkEndFunction: Undefined<BasicFunction>;

  clickTimer: TimeoutID | null;

  currentEntitlementParams: NTGENTITLEMENT_PARAMS | null;

  currentFeedItemTime: number;

  // Stream Id of Videofutur VOD currently being played (changes on every pause/play)
  currentVideofuturStreamId: number | null;

  // Timers used to collect data during start (ramp up period)
  dataCollectionRampUpTimers: Array<TimeoutID>;

  // Settings for data collection (enabled, stream type, ramp up, frequency)
  dataCollectionSettings: DATA_COLLECTION_MESSAGE_SETTINGS | null;

  // Timer used for the data collection heartbeat
  dataCollectionSamplingTimer: IntervalID | null;

  debugInfoMetricsTimer: TimeoutID | null;

  distributorId: string;

  hidePlayPicto: boolean;

  initialChannelId: string | null;

  // Used to avoid closing the player when starting a recording that's still being recorded in its end margin (but no more in the user view)
  isFirstPass: boolean;

  isOverlayHovered: boolean;

  isPlayStarted: boolean;

  isRestartingSession: boolean;

  isSubtitlesAutoSelectionApplied: boolean;

  isZapping: boolean;

  keepAlive: KeepAlive | null;

  lastViewingHistoryItem: {| locationId: ?string, playheadPosition: number |} | null;

  mediametrie: Mediametrie | null;

  nextChannel: NETGEM_API_CHANNEL | null;

  overlayTimer: TimeoutID | null;

  playbackRateIndex: number;

  // Only used for debug purpose
  playerSessionStart: number;

  resumeTimer: TimeoutID | null;

  videoContainer: HTMLElement | null;

  videoController: React.ElementRef<any> | null;

  videoElement: HTMLVideoElement | null;

  videoPlayer: PlayerShaka | null;

  constructor(props: CompletePlayerPropType) {
    super(props);

    this.abortController = new AbortController();
    this.checkEndFunction = undefined;
    this.clickTimer = null;
    this.currentEntitlementParams = null;
    this.currentFeedItemTime = 0;
    this.currentVideofuturStreamId = null;
    this.dataCollectionRampUpTimers = [];
    this.dataCollectionSamplingTimer = null;
    this.dataCollectionSettings = Collector.getPlayerStateSettings();
    this.distributorId = '';
    this.hidePlayPicto = true;
    this.initialChannelId = null;
    this.isFirstPass = true;
    this.isOverlayHovered = false;
    this.isPlayStarted = false;
    this.isRestartingSession = false;
    this.isSubtitlesAutoSelectionApplied = false;
    this.isZapping = false;
    this.keepAlive = null;
    this.lastViewingHistoryItem = null;
    this.mediametrie = null;
    this.nextChannel = null;
    this.overlayTimer = null;
    this.playbackRateIndex = 9;
    this.playerSessionStart = AccurateTimestamp.now();
    this.resumeTimer = null;
    this.videoContainer = null;
    this.videoController = null;
    this.videoElement = null;
    this.videoPlayer = null;

    fscreen.onfullscreenchange = this.onFullScreenChange;

    const {
      settings: { [Setting.Volume]: volume },
    } = props;

    this.state = {
      ...InitialState,
      volume,
    };
  }

  async componentDidMount(): Promise<void> {
    const { playerItem, playerOfflineContent } = this.props;

    window.addEventListener('keydown', this.handleLevelControlKey);
    window.addEventListener('keyup', this.handleLevelControlKey);
    window.addEventListener('beforeunload', this.stopBOStream, { passive: true });

    Messenger.on(MessengerEvents.AUTHENTICATION_REQUIRED, this.handleAuthenticationRequired);

    HotKeys.register(['ctrl+shift+d', 'alt+shift+d'], this.handleShowDebugHotKey, { name: 'Player.showDebug' });
    HotKeys.register('alt+ctrl+shift+d', this.handleShowDebugOverlayHotKey, { name: 'Player.showDebugOverlay' });
    HotKeys.register('f', this.handleFullscreenHotKey, { name: 'Player.fullscreen' });
    HotKeys.register('h', this.handleHideControlsHotKey, { name: 'Player.hideControls' });
    HotKeys.register('i', this.handleShowInfoHotKey, { name: 'Player.showInfo' });
    HotKeys.register('m', this.handleMuteHotKey, { name: 'Player.mute' });
    HotKeys.register('l', this.handleGoBackToLiveHotKey, { name: 'Player.backToLive' });
    HotKeys.register(['left', 'alt+left', 'ctrl+left', 'shift+left'], this.handleBackwardHotKey, { name: 'Player.backward' });
    HotKeys.register(['right', 'alt+right', 'ctrl+right', 'shift+right'], this.handleForwardHotKey, { name: 'Player.forward' });
    HotKeys.register('down', this.handlePreviousChannelHotKey, { name: 'Player.previousChannel' });
    HotKeys.register('up', this.handleNextChannelHotKey, { name: 'Player.nextChannel' });
    HotKeys.register('space', this.handlePlayHotKey, { name: 'Player.play' });
    HotKeys.register('escape', this.handleExitHotKey, { name: 'Player.exit' });
    HotKeys.register(['minus', 'numpad_minus'], this.handleVolumeDownHotKey, { name: 'Player.volumeDown' });
    HotKeys.register(['plus', 'numpad_plus'], this.handleVolumeUpHotKey, { name: 'Player.volumeUp' });
    HotKeys.register('less_than', this.handleSlowerHotKey, { name: 'Player.slower' });
    HotKeys.register('shift+less_than', this.handleFasterHotKey, { name: 'Player.faster' });
    HotKeys.register(['ctrl+shift+less_than', 'alt+shift+less_than'], this.handleNormalSpeedHotKey, { name: 'Player.normalSpeed' });

    if (playerOfflineContent !== null) {
      // Offline content
      return await this.openOfflineContent(playerOfflineContent);
    }

    if (playerItem === null) {
      return Promise.resolve();
    }

    // Everything else
    const { authority, item, locationId, programMetadata = null, seriesMetadata = null, type, viewingHistoryId = null, vtiId = null } = playerItem;

    if (type === ExtendedItemType.TV) {
      // Live, catchup, recording and SVOD with Netgem authority
      return this.openTVItem(item, programMetadata, seriesMetadata, authority);
    } else if (programMetadata) {
      fireAndForget(this.loadImageFromMetadata(programMetadata, seriesMetadata));
      if (type === ExtendedItemType.Trailer) {
        // Trailer
        return this.openTrailerItem(programMetadata, seriesMetadata);
      }

      // TVOD, EST and SVOD with non-Netgem authority
      return this.openVodItem(item, programMetadata, seriesMetadata, vtiId, viewingHistoryId, locationId ?? null);
    }

    return Promise.resolve();
  }

  componentDidUpdate(prevProps: CompletePlayerPropType, prevState: PlayerStateType) {
    const {
      applicationName,
      npvrRecordingsList,
      settings: { [Setting.GreenStreaming]: isBitrateLimited },
    } = this.props;
    const {
      npvrRecordingsList: prevNpvrRecordingsList,
      settings: { [Setting.GreenStreaming]: prevIsBitrateLimited },
    } = prevProps;
    const { channel, contentType, currentItem, isControllerEnabled, isDebugOverlayVisible, isOverlayVisible, liveBufferLength, playheadPosition, programMetadata, resumeState, timeshift } = this.state;
    const {
      channel: prevChannel,
      currentItem: prevCurrentItem,
      isControllerEnabled: prevIsControllerEnabled,
      isDebugOverlayVisible: prevIsDebugOverlayVisible,
      isOverlayVisible: prevIsOverlayVisible,
      liveBufferLength: prevLiveBufferLength,
      playheadPosition: prevPlayheadPosition,
      programMetadata: prevProgramMetadata,
      resumeState: prevResumeState,
      timeshift: prevTimeshift,
    } = prevState;
    const { checkEndFunction, mediametrie } = this;

    if (isBitrateLimited !== prevIsBitrateLimited) {
      this.updateMaxBitrate(isBitrateLimited);
    }

    if (channel !== prevChannel || liveBufferLength !== prevLiveBufferLength) {
      this.updateTimeshiftStatus(liveBufferLength);
    }

    if (isDebugOverlayVisible !== prevIsDebugOverlayVisible) {
      this.toggleDebugInfoMetrics(isDebugOverlayVisible);
    }

    if (currentItem && currentItem !== prevCurrentItem) {
      // Live playing: item changed
      MediaController.initialize(applicationName);
      this.mcLoadProgramImage();
      fireAndForget(this.loadLiveItemLocationMetadata(currentItem));
    }

    if (playheadPosition !== prevPlayheadPosition && checkEndFunction) {
      checkEndFunction();
    }

    if (contentType === ContentType.Live && prevChannel !== channel && channel) {
      // Channel has changed: search new item in EPG
      this.findPlayedItemInEpgFeed(channel, playheadPosition);
    }

    if (programMetadata && programMetadata !== prevProgramMetadata) {
      // Refresh recordings in case the new item is being recorded
      this.refreshNpvr();
    }

    if ((timeshift === 0 && prevTimeshift > TIMESHIFT_THRESHOLD) || (timeshift > TIMESHIFT_THRESHOLD && prevTimeshift === 0)) {
      this.setTimeshiftSwitch(true);
    }

    if (mediametrie && timeshift !== prevTimeshift) {
      mediametrie.updateTimeshift(timeshift);
    }

    if (!isOverlayVisible && prevIsOverlayVisible) {
      Messenger.emit(MessengerEvents.CLOSE_CHANNEL_LIST);
    }

    if (resumeState === ResumeState.PromptUserIfNeeded && resumeState !== prevResumeState) {
      this.promptUserToResume();
    }

    if (npvrRecordingsList !== prevNpvrRecordingsList) {
      this.checkLiveRecordingStatus();
    }

    if (isControllerEnabled && isControllerEnabled !== prevIsControllerEnabled) {
      this.checkDebugOverlayAutoOpen();
    }
  }

  componentWillUnmount() {
    const { abortController, mediametrie } = this;

    abortController.abort('Component Player will unmount');

    window.removeEventListener('keydown', this.handleLevelControlKey);
    window.removeEventListener('keyup', this.handleLevelControlKey);
    window.removeEventListener('beforeunload', this.stopBOStream, { passive: true });

    Messenger.off(MessengerEvents.AUTHENTICATION_REQUIRED, this.handleAuthenticationRequired);

    HotKeys.unregister(['ctrl+shift+d', 'alt+shift+d'], this.handleShowDebugHotKey);
    HotKeys.unregister('alt+ctrl+shift+d', this.handleShowDebugOverlayHotKey);
    HotKeys.unregister('f', this.handleFullscreenHotKey);
    HotKeys.unregister('h', this.handleHideControlsHotKey);
    HotKeys.unregister('i', this.handleShowInfoHotKey);
    HotKeys.unregister('m', this.handleMuteHotKey);
    HotKeys.unregister('l', this.handleGoBackToLiveHotKey);
    HotKeys.unregister('left', this.handleBackwardHotKey);
    HotKeys.unregister('right', this.handleForwardHotKey);
    HotKeys.unregister('down', this.handlePreviousChannelHotKey);
    HotKeys.unregister('up', this.handleNextChannelHotKey);
    HotKeys.unregister('space', this.handlePlayHotKey);
    HotKeys.unregister('escape', this.handleExitHotKey);
    HotKeys.unregister(['minus', 'numpad_minus'], this.handleVolumeDownHotKey);
    HotKeys.unregister(['plus', 'numpad_plus'], this.handleVolumeUpHotKey);
    HotKeys.unregister('less_than', this.handleSlowerHotKey);
    HotKeys.unregister('shift+less_than', this.handleFasterHotKey);
    HotKeys.unregister(['ctrl+shift+less_than', 'alt+shift+less_than'], this.handleNormalSpeedHotKey);

    this.resetOverlayTimer();
    this.resetResumeTimer();
    this.resetClickTimer();
    this.resetDebugInfoMetricsTimer();

    if (mediametrie) {
      mediametrie.notifyStop();
      mediametrie.destroy();
      this.mediametrie = null;
    }
  }

  // Show debug info
  handleShowDebugHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    const { isDebugModeEnabled } = this.props;
    const { videoPlayer } = this;

    event.preventDefault();
    event.stopPropagation();

    if (isDebugModeEnabled) {
      this.showDebugInfo();

      if (videoPlayer) {
        videoPlayer.showDebug();
      }
    }
  };

  // Toggle debug overlay
  handleShowDebugOverlayHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    const { isControllerEnabled } = this.state;

    if (!isControllerEnabled) {
      // Play not started yet
      return;
    }

    event.preventDefault();
    event.stopPropagation();

    this.setState(
      produce((draft) => {
        draft.isDebugOverlayVisible = !draft.isDebugOverlayVisible;
      }),
    );
  };

  setDebugInfoUrls = (streamUrl: string, laUrl: string = ''): void => {
    this.setState(
      produce((draft) => {
        draft.debugInfo.laUrl = laUrl;
        draft.debugInfo.streamUrl = streamUrl;
      }),
    );
  };

  checkDebugOverlayAutoOpen = () => {
    const {
      isDebugModeEnabled,
      settings: { [Setting.AutoOpenPlayerDebugPanel]: autoOpenPlayerDebugPanel },
    } = this.props;

    if (!isDebugModeEnabled || !autoOpenPlayerDebugPanel) {
      return;
    }

    this.setState({ isDebugOverlayVisible: true });
  };

  /*
   * Attempt at correctly closing the stream when page unloads (navigation, refresh, etc.)
   * WARNING: no guarantee of success (but "forgotten" streams are closed at next startup in this case)
   */
  stopBOStream = () => {
    const { localSendVideofuturStreamStopRequest } = this.props;
    const { currentVideofuturStreamId, distributorId } = this;

    if (currentVideofuturStreamId) {
      localSendVideofuturStreamStopRequest(distributorId, currentVideofuturStreamId, this.getBookmark(), undefined, true);
    }
  };

  // Toggle fullscreen
  handleFullscreenHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.toggleFullscreen();
  };

  // Hide controls
  handleHideControlsHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.hideControls();
  };

  // Show program card
  handleShowInfoHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.handleInfoOnClick();
  };

  // Toggle sound
  handleMuteHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.handleVolumeOnClick();
  };

  // Skip backward
  handleBackwardHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.skip(SkipDirection.Backward, event);
  };

  // Skip forward
  handleForwardHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.skip(SkipDirection.Forward, event);
  };

  // Previous channel (live only)
  handlePreviousChannelHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    const { contentType } = this.state;

    event.preventDefault();
    event.stopPropagation();

    if (contentType === ContentType.Live) {
      this.goToPreviousChannel();
    }
  };

  // Next channel (live only)
  handleNextChannelHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    const { contentType } = this.state;

    event.preventDefault();
    event.stopPropagation();

    if (contentType === ContentType.Live) {
      this.goToNextChannel();
    }
  };

  // Toggle play/pause
  handlePlayHotKey = (event: SyntheticKeyboardEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    return this.togglePlayVideo();
  };

  // Exit
  handleExitHotKey = (event: SyntheticKeyboardEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    return this.handleClose({ errorCode: NO_ERROR_CODE });
  };

  // Sound down
  handleVolumeDownHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.changeVolume(-VOLUME_STEP);
  };

  // Sound up
  handleVolumeUpHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.changeVolume(VOLUME_STEP);
  };

  setPlaybackRate = (playbackRateIndex: number) => {
    const { contentType, isControllerEnabled, isTimeshiftEnabled } = this.state;

    const { videoElement } = this;

    if (!isControllerEnabled || (contentType === ContentType.Live && !isTimeshiftEnabled)) {
      // Play not started yet or timeshift disabled
      return;
    }

    if (!videoElement) {
      return;
    }

    this.playbackRateIndex = playbackRateIndex;
    videoElement.playbackRate = PLAYBACK_RATES[playbackRateIndex];

    logDebug(`Playback rate: ${videoElement.playbackRate}`);
  };

  handleSlowerHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    const { playbackRateIndex } = this;

    event.preventDefault();
    event.stopPropagation();

    if (playbackRateIndex > 0) {
      this.setPlaybackRate(playbackRateIndex - 1);
    }
  };

  handleFasterHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    const { playbackRateIndex } = this;

    event.preventDefault();
    event.stopPropagation();

    if (playbackRateIndex < PLAYBACK_RATES.length - 1) {
      this.setPlaybackRate(playbackRateIndex + 1);
    }
  };

  handleNormalSpeedHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();

    this.setPlaybackRate(NORMAL_PLAYBACK_INDEX);
  };

  handleLevelControlKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    this.setState({ controlLevel: eventToControlLevel(event) });
  };

  updateTimeshiftStatus = (liveBufferLength: number) => {
    const { channels } = this.props;
    const { channel } = this.state;

    this.setState({ isTimeshiftEnabled: channelHasTimeshift(channels, channel?.epgid) && liveBufferLength > 0 });
  };

  // Functions starting with 'mc' are only used for Global Media Controls

  initializeMediaController = () => {
    const { applicationName } = this.props;
    const { contentType, imageUrl, isTrailer, isVideofuturAsset } = this.state;

    MediaController.initialize(applicationName);

    if (isTrailer || isVideofuturAsset) {
      // VOD item or VOD trailer
      if (imageUrl) {
        MediaController.setImage(imageUrl, 'image/jpeg', VOD_TILE_WIDTH, VOD_TILE_HEIGHT);
      }
    } else {
      // Live, catchup or recording
      this.mcLoadProgramImage();
    }

    MediaController.setPlayHandler(this.playVideo);
    MediaController.setPauseHandler(this.pauseVideo);
    MediaController.setSeekBackwardHandler(this.mcSkipBackward);
    MediaController.setSeekForwardHandler(this.mcSkipForward);

    if (contentType === ContentType.Live) {
      MediaController.setPreviousTrackHandler(this.goToPreviousChannel);
      MediaController.setNextTrackHandler(this.goToNextChannel);
    } else {
      MediaController.setPreviousTrackHandler(undefined);
      MediaController.setNextTrackHandler(undefined);
    }
  };

  goToPreviousChannel = () => {
    const { videoController } = this;

    if (!videoController) {
      return;
    }

    videoController.goToPreviousChannel();
    this.showOverlay();
  };

  goToNextChannel = () => {
    const { videoController } = this;

    if (!videoController) {
      return;
    }

    videoController.goToNextChannel();
    this.showOverlay();
  };

  mcSkipBackward = () => {
    this.skip(SkipDirection.Backward);
  };

  mcSkipForward = () => {
    this.skip(SkipDirection.Forward);
  };

  mcLoadProgramImage = () => {
    const { localGetImageUrl } = this.props;
    const { currentItem } = this.state;
    const {
      abortController: { signal },
    } = this;

    if (!currentItem) {
      return;
    }

    const { selectedProgramId } = currentItem;

    localGetImageUrl(selectedProgramId, GMC_IMAGE_WIDTH, GMC_IMAGE_HEIGHT, undefined, signal)
      .then((imageUrl: string) => MediaController.setImage(imageUrl, 'image/jpeg', GMC_IMAGE_WIDTH, GMC_IMAGE_HEIGHT))
      .catch((error) => ignoreIfAborted(signal, error));
  };

  showDebugInfo = () => {
    const {
      currentFeedItemTime,
      props,
      state,
      state: {
        bufferedTimeRanges,
        dataCollectionLastPlaybackTime,
        dataCollectionStartTime,
        duration,
        endMargin,
        liveBufferLength,
        playheadPosition,
        realEnd,
        realStart,
        startMargin,
        timeshift,
        totalDuration,
        userViewEndOffset,
        userViewStartOffset,
      },
    } = this;

    showDebug('Player', {
      instance: this,
      instanceFields: [
        'currentEntitlementParams',
        'currentVideofuturStreamId',
        'dataCollectionSettings',
        'distributorId',
        'hidePlayPicto',
        'initialChannelId',
        'isFirstPass',
        'isOverlayHovered',
        'isPlayStarted',
        'isSubtitlesAutoSelectionApplied',
        'isZapping',
        'lastViewingHistoryItem',
        'mediametrie',
        'nextChannel',
        'videoPlayer',
      ],
      misc: {
        bufferedTimeRanges: getTimeRangesAsString(bufferedTimeRanges),
        currentFeedItemTime: getTimestampDisplay(currentFeedItemTime),
        dataCollectionLastPlaybackTime: getTimestampDisplay(dataCollectionLastPlaybackTime),
        dataCollectionStartTime: getTimestampDisplay(dataCollectionStartTime),
        duration: getDurationDisplay(duration),
        endMargin: getDurationDisplay(endMargin),
        liveBufferLength: getDurationDisplay(liveBufferLength),
        playheadPosition: getTimestampDisplay(playheadPosition),
        realEnd: getTimestampDisplay(realEnd),
        realStart: getTimestampDisplay(realStart),
        startMargin: getDurationDisplay(startMargin),
        timeshift: getDurationDisplay(timeshift),
        totalDuration: getDurationDisplay(totalDuration),
        userViewEndOffset: getDurationDisplay(userViewEndOffset),
        userViewStartOffset: getDurationDisplay(userViewStartOffset),
      },
      props,
      propsFields: ['isDebugModeEnabled', 'playerItem', 'playerOfflineContent', 'settings'],
      state,
      stateFields: [
        'audioMediaInfo',
        'channel',
        'channelImageUrl',
        'contentType',
        'controlLevel',
        'currentItem',
        'dataCollectionLastPlaybackTime',
        'dataCollectionMessage',
        'dataCollectionStartTime',
        'debugInfo',
        'duration',
        'endMargin',
        'externalSubtitles',
        'forcedResumePosition',
        'imageUrl',
        'isBOStreamRequestPending',
        'isBuffering',
        'isControllerEnabled',
        'isCurrentItemBlackedOut',
        'isDebugOverlayVisible',
        'isInFullscreen',
        'isLiveRecording',
        'isMuted',
        'isOverlayVisible',
        'isPlaying',
        'isTimeshiftEnabled',
        'isTimeshiftSwitching',
        'isTrailer',
        'isVideoQualityAuto',
        'isVideofuturAsset',
        'liveBufferLength',
        'location',
        'locationId',
        'locationMetrics',
        'locationStatus',
        'playheadPosition',
        'programInfoLoadStatus',
        'programMetadata',
        'realEnd',
        'realStart',
        'resumePosition',
        'resumeState',
        'selectedAudioMediaInfo',
        'selectedSubtitlesMediaInfo',
        'selectedVideoQuality',
        'seriesEpisodeText',
        'seriesMetadata',
        'skipping',
        'startMargin',
        'subtitlesMediaInfo',
        'timeshift',
        'title',
        'totalDuration',
        'userViewEndOffset',
        'userViewStartOffset',
        'videoStreamData',
        'viewingHistoryId',
        'volume',
        'vtiId',
      ],
    });
  };

  startDebugInfoMetricsTimer = () => {
    this.resetDebugInfoMetricsTimer();
    this.debugInfoMetricsTimer = setTimeout(this.updateDebugInfoMetrics, DEBUG_INFO_METRICS_TIMEOUT);
  };

  resetDebugInfoMetricsTimer = () => {
    if (this.debugInfoMetricsTimer) {
      clearTimeout(this.debugInfoMetricsTimer);
      this.debugInfoMetricsTimer = null;
    }
  };

  resetOverlayTimer = () => {
    if (this.overlayTimer) {
      clearTimeout(this.overlayTimer);
      this.overlayTimer = null;
    }
  };

  resetResumeTimer = () => {
    if (this.resumeTimer) {
      clearTimeout(this.resumeTimer);
      this.resumeTimer = null;
    }
  };

  resetClickTimer = () => {
    if (this.clickTimer) {
      clearTimeout(this.clickTimer);
      this.clickTimer = null;
    }
  };

  displayChannelNotAvailableMsg = () => {
    Messenger.emit(MessengerEvents.NOTIFY_ERROR, <div>{Localizer.localize('player.errors.channel_unavailable')}</div>);
    this.finishZapping();
  };

  displayErrorMessage = (code: string, message: ?string, isLargeNotificationNeeded?: boolean) => {
    const msgText = message ? message : Localizer.localize('common.messages.errors.retry');
    const codeElement = code !== '' ? <div className='errorCode'>{Localizer.localize('common.messages.errors.code', { code })}</div> : null;

    Messenger.emit(
      MessengerEvents.NOTIFY_ERROR,
      <>
        <div className='errorMessage'>{msgText}</div>
        {codeElement}
      </>,
      isLargeNotificationNeeded ? { className: 'large' } : undefined,
    );
  };

  handleAuthenticationRequired = async () => {
    await this.handleClose({ errorCode: NO_ERROR_CODE });
  };

  promptUserToCancelDownload = (operationId: string): void => {
    const { localShowConfirmation } = this.props;

    const data = {
      button1Title: 'Interrompre & Fermer',
      button2Title: 'Poursuivre',
      question: "Si vous quittez le lecteur vidéo, le téléchargement en cours sera interrompu.\n\nSouhaitez-vous poursuivre ce téléchargement ou bien l'interrompre et fermer le lecteur ?",
      title: 'Téléchargement en cours',
    };

    Messenger.once(MessengerEvents.MODAL_CONFIRMATION_CLOSED, (result: ConfirmationModalResult) => this.cancelDownloadConfirmationClosedCallback(result, operationId));
    localShowConfirmation(data);
  };

  cancelDownloadConfirmationClosedCallback = (result: ConfirmationModalResult, operationId: string): Promise<void> => {
    if (result !== ConfirmationModalResult.Button1) {
      return Promise.resolve();
    }

    // Close toast
    Messenger.emit(MessengerEvents.NOTIFY_CLOSE, operationId);

    // Cancel download
    ShakaStorage.cancelDownload(operationId);

    // Close player
    return this.closePlayer({ errorCode: NO_ERROR_CODE });
  };

  handleClose = (data: CloseData): Promise<void> => {
    const { errorCode } = data;
    const { downloadOperationId } = this.state;

    if (errorCode === NO_ERROR_CODE && downloadOperationId !== null) {
      // User tried to exit player while a download was in progress
      this.promptUserToCancelDownload(downloadOperationId);
      return Promise.resolve();
    }

    return this.closePlayer(data);
  };

  closePlayer = async (data: CloseData): Promise<void> => {
    const { errorCode, errorMsg, isSafariWorkaround, isLargeNotificationNeeded } = data;
    const { closeCallback, closeConfirmation } = this.props;

    MediaController.reset();

    if (!isSafariWorkaround) {
      // Send last player state and flush
      this.sendCurrentPlayerState(errorCode ? DataCollectionPlayerState.Error : DataCollectionPlayerState.Stop, false, errorCode);
      Messenger.emit(MessengerEvents.FLUSH_COLLECTOR);
    }

    this.stopDataCollection();

    const { fullscreenElement, fullscreenEnabled } = fscreen;
    if (fullscreenElement !== null && fullscreenEnabled) {
      fscreen.exitFullscreen();
    }

    closeConfirmation();

    // Notify user except for specific codes which should be ignored
    if (errorCode && !shouldErrorBeIgnored(errorCode)) {
      this.displayErrorMessage(errorCode, errorMsg, isLargeNotificationNeeded);
    }

    try {
      await this.stopVideo(StopStreamKind.FinalStop);
    } catch {
      // Error can be ignored
    } finally {
      closeCallback(isSafariWorkaround === true);
    }
  };

  checkEnd = () => {
    const { duration, playheadPosition, userViewEndOffset } = this.state;
    const { isFirstPass, videoPlayer } = this;

    if (playheadPosition > duration - userViewEndOffset) {
      if (isFirstPass) {
        // First time this code is reached is during initialization
        this.isFirstPass = false;
      } else {
        videoPlayer?.endedEvent();
      }
    }
  };

  refreshNpvr = () => {
    Messenger.emit(MessengerEvents.REFRESH_NPVR);
  };

  updateViewingHistory = () => {
    const { localUpdateViewingHistory } = this.props;
    const { contentType, isTrailer, resumeState } = this.state;

    if (isTrailer || contentType === ContentType.Live || (resumeState !== ResumeState.Done && resumeState !== ResumeState.NoResumePosition)) {
      return;
    }

    const item = this.getViewingHistoryItem();
    if (!item) {
      return;
    }

    localUpdateViewingHistory(item);
  };

  // Margins are removed so that only the program's duration is taken into account
  getViewingHistoryItem = (): NETGEM_API_VIEWINGHISTORY_ITEM | null => {
    const { viewingHistory } = this.props;
    const { currentItem, duration, realEnd, realStart } = this.state;
    const { playheadPosition: exactPlayheadPosition } = this.state;
    const { lastViewingHistoryItem } = this;

    const viewingHistoryIds = this.getViewingHistoryIds();
    if (!viewingHistoryIds) {
      return null;
    }

    const { locationId, programId, seriesId } = viewingHistoryIds;
    if (!locationId) {
      return null;
    }

    const channelId = currentItem?.selectedLocation.channelId;
    const playheadPosition = Math.floor(exactPlayheadPosition);

    // Check last viewing history item before sending then same update request
    if (lastViewingHistoryItem) {
      const { locationId: lastLocationId, playheadPosition: lastPlayheadPosition } = lastViewingHistoryItem;
      if (lastLocationId === locationId && lastPlayheadPosition === playheadPosition) {
        return null;
      }
    }

    // Only saved for checking purpose
    this.lastViewingHistoryItem = {
      locationId,
      playheadPosition,
    };

    return buildViewingHistoryItem(viewingHistory, locationId, programId, seriesId, playheadPosition, duration, realStart, realEnd, channelId);
  };

  getViewingHistoryIds = (): ViewingHistoryIds | null => {
    const { playerItem } = this.props;
    const { locationId, viewingHistoryId: stateViewingHistoryId } = this.state;

    if (!playerItem?.programMetadata) {
      return null;
    }

    const { programMetadata, seriesMetadata } = playerItem;

    const { providerInfo: programProviderInfo } = programMetadata;
    if (programProviderInfo) {
      // VOD item
      if (!stateViewingHistoryId) {
        // Location Id is missing
        return null;
      }

      const { viewingHistoryId } = programProviderInfo;
      return {
        isVod: true,
        locationId: stateViewingHistoryId,
        programId: viewingHistoryId,
        seriesId: seriesMetadata?.providerInfo.viewingHistoryId ?? null,
      };
    }

    // TV item
    const { id } = programMetadata;

    return {
      isVod: false,
      locationId,
      programId: id,
      seriesId: seriesMetadata?.id ?? null,
    };
  };

  getResumeData = (locationId: string | null, programId: string, seriesId: string | null): ResumeData => {
    const { viewingHistory } = this.props;
    const { duration, realStart, userViewEndOffset } = this.state;

    let resumePosition = -1;
    let item: NETGEM_API_VIEWINGHISTORY_ITEM | null = null;

    if (seriesId) {
      // Series episode
      const { [seriesId]: series } = viewingHistory;
      const episodes = series?.episodes;

      if (episodes) {
        item = episodes.find((ep) => ep.id === programId) ?? null;
      }
    } else {
      // Single
      ({ [programId]: item } = viewingHistory);
    }

    if (item && locationId) {
      const { playeditems } = item;
      if (playeditems) {
        const { [locationId]: matchingItem } = playeditems;

        if (matchingItem) {
          const { position } = matchingItem;

          if (position > 0) {
            resumePosition = position + realStart;

            const maxPositionThreshold = duration - userViewEndOffset - RESUME_REWIND_DURATION;
            if (resumePosition > maxPositionThreshold) {
              resumePosition = -1;
            }
          }
        }
      }
    }

    return {
      resumePosition,
      resumeState: resumePosition > 0 ? ResumeState.PromptUserIfNeeded : ResumeState.NoResumePosition,
    };
  };

  // Margins are added so that the returned value is a valid seek position
  checkResumePosition = () => {
    const { playerItem, viewingHistoryStatus } = this.props;
    const { duration, forcedResumePosition, locationId: fullLocationId, resumeState: currentResumeState } = this.state;

    if (forcedResumePosition !== null) {
      // Broadpeak session expired and has been restarted
      this.setState({
        resumePosition: forcedResumePosition,
        resumeState: ResumeState.PromptUserIfNeeded,
      });
      return;
    }

    if (currentResumeState !== ResumeState.NotChecked) {
      // Already checked
      return;
    }

    const viewingHistoryIds = this.getViewingHistoryIds();

    if (!viewingHistoryIds) {
      this.setState({
        resumePosition: -1,
        resumeState: ResumeState.NoResumePosition,
      });
      return;
    }

    const { isVod, locationId, programId, seriesId } = viewingHistoryIds;

    if (viewingHistoryStatus === LoadableStatus.NotInitialized) {
      this.setState({
        resumePosition: -1,
        resumeState: ResumeState.NotChecked,
      });
      return;
    }

    if (viewingHistoryStatus === LoadableStatus.Error || !locationId || !programId || !duration) {
      this.setState({
        resumePosition: -1,
        resumeState: ResumeState.NoResumePosition,
      });
      return;
    }

    let resumeData = this.getResumeData(locationId, programId, seriesId);
    const { resumeState } = resumeData;
    if (playerItem && resumeState === ResumeState.NoResumePosition && isVod) {
      // Backward compatibility: check resume position with full location Id
      const { programMetadata, seriesMetadata } = playerItem;

      if (programMetadata) {
        const { id } = programMetadata;
        resumeData = this.getResumeData(fullLocationId, id, seriesMetadata?.id ?? null);
      }
    }

    this.setState(resumeData);
  };

  stopMediametrie = () => {
    const { mediametrie } = this;

    if (mediametrie) {
      mediametrie.notifyStop();
      mediametrie.destroy();
      this.mediametrie = null;
    }
  };

  stopVideo = async (stopType: StopStreamKind): Promise<any> => {
    const { keepAlive, videoElement, videoPlayer } = this;

    this.stopMediametrie();

    if (videoElement?.textTracks) {
      // Hide subtitles (if any)
      for (let i = 0; i < videoElement.textTracks.length; ++i) {
        videoElement.textTracks[i].mode = 'hidden';
      }
    }

    if (stopType === StopStreamKind.FinalStop && videoPlayer) {
      // Clean up video player
      await videoPlayer.reset();
      this.videoPlayer = null;

      if (keepAlive) {
        await keepAlive.stop();
      }
    }

    return this.sendStreamStop(stopType);
  };

  setInitAudioAndSubtitlesLanguages = (data: VideoPlayerInitData): VideoPlayerInitData => {
    const {
      settings: {
        [Setting.AutoSelectAudioTrack]: autoSelectAudioTrack,
        [Setting.AutoSelectSubtitlesTrack]: autoSelectSubtitlesTrack,
        [Setting.LastAudioTrack]: lastAudioTrack,
        [Setting.LastSubtitlesTrack]: lastSubtitlesTrack,
      },
    } = this.props;

    return {
      ...data,
      audioLanguage: autoSelectAudioTrack && lastAudioTrack ? lastAudioTrack : undefined,
      subtitlesLanguage: autoSelectSubtitlesTrack && lastSubtitlesTrack ? lastSubtitlesTrack : undefined,
    };
  };

  plugCallbacks = (videoPlayer: PlayerShaka): void => {
    if (videoPlayer.bufferingCallback) {
      // Callbacks already plugged in (we're probably zapping between two live channels)
      return;
    }

    videoPlayer.bufferingCallback = this.buffering;
    videoPlayer.bufferLoadedCallback = this.bufferLoaded;
    videoPlayer.errorCallback = this.playerError;
    videoPlayer.liveBufferLengthUpdatedCallback = this.liveBufferLengthUpdated;
    videoPlayer.playbackEndedCallback = this.playbackEnded;
    videoPlayer.playbackPausedCallback = this.playbackPaused;
    videoPlayer.playbackPlayingCallback = this.playbackPlaying;
    videoPlayer.playbackTimeUpdatedCallback = this.playbackTimeUpdated;
    videoPlayer.safariVodWorkaroundCallback = this.safariVodWorkaround;
    videoPlayer.streamInfoUpdatedCallback = this.streamInfoUpdated;
    videoPlayer.streamInitializedCallback = this.streamInitialized;
    videoPlayer.volumeChangedCallback = this.setVolume;
  };

  createVideoPlayer = (): PlayerShaka | null => {
    const {
      authenticationToken,
      settings: { [Setting.BufferBehind]: bufferBehind, [Setting.BufferingGoal]: bufferingGoal, [Setting.RebufferingGoal]: rebufferingGoal },
    } = this.props;
    const { contentType, isVideofuturAsset, title } = this.state;
    const { videoContainer, videoElement, videoPlayer } = this;

    if (videoPlayer) {
      // Reuse existing player in case of channel change while playing live stream
      return videoPlayer;
    }

    if (!videoElement || !videoContainer) {
      // No HTML video element or container as parent (not supposed to happen)
      return null;
    }

    const settings = {
      bufferBehind,
      bufferingGoal,
      rebufferingGoal,
    };

    // Instantiate Shaka Player (passing video container makes Shaka uses its UITextDisplayer)
    try {
      return new PlayerShaka(videoElement, videoContainer, settings, authenticationToken, isVideofuturAsset, contentType, title ?? 'unknown title');
    } catch (error) {
      const { message } = error;

      Messenger.emit(MessengerEvents.NOTIFY_ERROR, <div className='errorMessage'>{message === 'BrowserNotSupported' ? 'Navigateur non supporté' : message}</div>);
      return null;
    }
  };

  startVideoInternal = async (initData: VideoPlayerInitData | null, errorAction: ErrorCallback): Promise<void> => {
    const { channels, deviceSettings } = this.props;
    const { contentType, locationMetrics, locationStatus } = this.state;

    if (!initData) {
      return errorAction();
    }

    // Update stream type in debug info
    const { type: streamType } = initData;
    this.setState(
      produce((draft) => {
        draft.debugInfo.streamType = streamType;
      }),
    );

    this.videoPlayer = this.createVideoPlayer();
    const { videoPlayer } = this;

    if (!videoPlayer) {
      SentryWrapper.error({
        breadcrumbs: ['Function startVideoInternal'],
        context: getInitDataAsSentryContext(initData),
        message: 'Missing videoPlayer',
        tagName: SentryTagName.Component,
        tagValue: SentryTagValue.Player,
      });
      return errorAction();
    }

    this.plugCallbacks(videoPlayer);

    // Initialize eStat if metrics exist for current channel
    if (contentType === ContentType.Live || locationStatus === WebAppHelpersLocationStatus.Catchup) {
      const { channelId } = initData;
      const metrics = getChannelMetrics(channels, channelId, METRICS_ESTAT, contentType === ContentType.Live ? MetricsStreamType.Live : MetricsStreamType.Catchup, locationMetrics);

      if (metrics) {
        const forceOptOutConsent = parseBoolean(getSettingValueByNameFromDeviceSettings(deviceSettings, 'mediametrie', 'forceOptOutConsentType'));
        this.mediametrie = new Mediametrie(metrics, videoPlayer.name, videoPlayer.getVersion(), videoPlayer.getState, videoPlayer.getPosition, forceOptOutConsent);
      }
    }

    try {
      const data = await this.startVideoWithPromise(initData);

      if (data === null) {
        // Player exited while starting: silently close
        return Promise.reject(new PlayerError('7000'));
      }

      return await this.startVideoWithPromiseSuccess(videoPlayer, data);
    } catch (error) {
      // Start failed
      this.setState({ videoStreamData: null });
      const { code, message } = error;
      return this.handleClose({ errorCode: code === VIDEOPLAYER_ERRORS.VS996StartupCancelled ? null : (code ?? VIDEOPLAYER_ERRORS.VS998InternalError), errorMsg: message });
    }
  };

  startVideoWithPromiseSuccess = async (videoPlayer: PlayerShaka, data: VideoPlayerInitData): Promise<void> => {
    const {
      isMaxBitrateAllowed,
      maxBitrate,
      settings: { [Setting.GreenStreaming]: isBitrateLimited, [Setting.Volume]: volume },
      state,
    } = this.props;
    const { externalSubtitles, locationId } = this.state;
    const { keepAlive, videoElement } = this;

    // Now use this data to start the player

    // Pixel tracking
    const { trackingStart } = data;
    if (trackingStart) {
      const trackingUrl = generateApiUrl(trackingStart, null, state);
      if (trackingUrl) {
        fetch(trackingUrl, { mode: 'no-cors' }).catch(() => {
          // Errors while initializing pixel tracking are ignored
          logWarning('Error initializing pixel tracking');
        });
      }
    }

    // Set audio and subtitles track (if settings say so)
    const dataWithLanguage = this.setInitAudioAndSubtitlesLanguages(data);

    if (locationId && getLocationType(locationId) === NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD) {
      // Refresh purchase list to get new expiration for TVOD
      Messenger.emit(MessengerEvents.REFRESH_PURCHASE_LIST);
    }

    if (isMaxBitrateAllowed && isBitrateLimited && maxBitrate > 0) {
      // If maxBitrate is greater than 0, enforce it, otherwise sky is the limit
      dataWithLanguage.maxBitrate = maxBitrate;
    }

    try {
      // Initialize player
      await videoPlayer.initialize(dataWithLanguage);

      // Handle external subtitles (sidecar)
      const { drm } = data;
      if (videoElement && drm !== Drm.Fairplay && externalSubtitles.length > 0) {
        // Sidecar subtitles handled by Shaka Player
        await videoPlayer.addSubtitles(externalSubtitles);
      }

      videoPlayer.setVolume(volume);
      this.initializeMediaController();
      this.finishZapping();

      if (keepAlive) {
        keepAlive.start();
      }

      return Promise.resolve();
    } catch (error) {
      // $FlowFixMe: flow doesn't know DOMException
      if (error instanceof DOMException && error.name === 'AbortError') {
        // User probably exited player while it was loading: ignore error
        return Promise.resolve();
      }

      logError('Error initializing player');
      logError(error);
      throw error;
    }
  };

  startVideo = async (initData: VideoPlayerInitData | null, errorAction: ErrorCallback) => {
    try {
      await this.stopVideo(StopStreamKind.Initialization);

      if (!initData) {
        errorAction();
        return;
      }

      await this.startVideoInternal(initData, errorAction);
    } catch (error) {
      this.finishZapping();
    }
  };

  startIfReady = (errorAction: ErrorCallback): Promise<void> => {
    const { videoStreamData } = this.state;
    const { isPlayStarted } = this;

    if (isPlayStarted || !videoStreamData) {
      errorAction();
      return Promise.resolve();
    }

    this.isPlayStarted = true;

    return this.startVideo(videoStreamData, errorAction);
  };

  openOfflineContent = async (offlineContent: ShakaOfflineContent): Promise<void> => {
    this.videoPlayer = this.createVideoPlayer();
    const { videoPlayer } = this;

    if (!videoPlayer) {
      SentryWrapper.error({
        breadcrumbs: ['Function openOfflineContent'],
        context: offlineContent,
        message: 'Missing videoPlayer',
        tagName: SentryTagName.Component,
        tagValue: SentryTagValue.Player,
      });
      return this.handleClose({ errorCode: VIDEOPLAYER_ERRORS.VS998InternalError });
    }

    this.plugCallbacks(videoPlayer);

    const {
      appMetadata: { assetId, channelId, title },
    } = offlineContent;
    this.setState({ programInfoLoadStatus: ProgramInfoLoadStatus.Loaded, title });

    fireAndForget(this.loadChannelImage(channelId));

    if (assetId) {
      fireAndForget(this.loadImageFromAssetId(assetId));
    }

    try {
      await videoPlayer.initializeOffline();
      return await videoPlayer.startOffline(offlineContent);
    } catch (error) {
      return this.handleClose({ errorCode: VIDEOPLAYER_ERRORS.VS994OfflineContentError, errorMsg: error.message });
    }
  };

  // Live, catchup, recording and SVOD with Netgem authority
  openTVItem = (
    item: NETGEM_API_V8_FEED_ITEM,
    programMetadata: NETGEM_API_V8_METADATA_PROGRAM | null,
    seriesMetadata: NETGEM_API_V8_METADATA_SERIES | null,
    authority?: NETGEM_API_V8_AUTHENT_REALM,
  ) => {
    const { channels } = this.props;
    const {
      locType,
      selectedLocation: { channelId, id: locationId },
    } = item;

    this.showOverlay();

    const locationStatus = locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING ? WebAppHelpersLocationStatus.Recording : getLocationStatus(item, authority);

    if (!locationStatus) {
      return;
    }

    let title: string | null = null;
    const contentType = locationStatus === WebAppHelpersLocationStatus.Live ? ContentType.Live : ContentType.Static;

    if (programMetadata) {
      title = getTitle(programMetadata, Localizer.language);
      if (!title && seriesMetadata) {
        title = getTitle(seriesMetadata, Localizer.language);
      }
    }

    if (contentType === ContentType.Live) {
      this.initialChannelId = channelId ?? null;
    }

    const channel = channelId ? channels[channelId] : null;

    this.setState(
      produce((draft) => {
        draft.channel = channel;
        draft.contentType = contentType;
        draft.currentItem = item;
        draft.locationId = locationId;
        draft.locationStatus = locationStatus;
        draft.programMetadata = programMetadata;
        draft.seriesEpisodeText = formatSeasonEpisodeNbr(programMetadata);
        draft.seriesMetadata = seriesMetadata;
        draft.title = title;
      }),
      () => {
        if (locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD && programMetadata) {
          fireAndForget(this.loadImageFromMetadata(programMetadata, seriesMetadata));
        } else {
          fireAndForget(this.loadChannelImage(channelId));
        }
        this.checkLiveRecordingStatus();
        return this.initializePlayer();
      },
    );
  };

  checkLiveRecordingStatus = () => {
    const { contentType } = this.state;

    this.setState({ isLiveRecording: contentType === ContentType.Live && this.isBeingLiveRecorded() });
  };

  isBeingLiveRecorded = (): boolean => {
    const { npvrRecordingsList } = this.props;
    const { currentItem } = this.state;

    if (!currentItem) {
      return false;
    }

    const { selectedProgramId } = currentItem;
    const recording = selectedProgramId ? npvrRecordingsList[selectedProgramId] : null;
    return Boolean(recording?.some((r) => r.recordOutcome === RecordingOutcome.Recorded && isRecordingMatching(currentItem, r)));
  };

  loadImageFromAssetId = async (assetId: string): Promise<void> => {
    const { localGetImageUrl } = this.props;
    const {
      abortController: { signal },
    } = this;

    try {
      const url: string = await localGetImageUrl(assetId, COVER_IMAGE_WIDTH, COVER_IMAGE_HEIGHT, undefined, signal);
      this.setState({ imageUrl: url });
      return Promise.resolve();
    } catch (error) {
      return ignoreIfAborted(signal, error);
    }
  };

  loadImageFromMetadata = (programMetadata: NETGEM_API_V8_METADATA_PROGRAM, seriesMetadata: NETGEM_API_V8_METADATA_SERIES | null): Promise<void> => {
    const { id: programId } = programMetadata;
    const { id: seriesId } = seriesMetadata ?? {};

    return this.loadImageFromAssetId(seriesId ?? programId);
  };

  loadChannelImage = async (channelId: ?string): Promise<void> => {
    const { channels, localGetImageUrl } = this.props;
    const { currentItem, programMetadata, seriesMetadata } = this.state;
    const {
      abortController: { signal },
    } = this;

    const channelImageId = getChannelImageId(channels, channelId);

    if (!channelImageId) {
      return Promise.resolve();
    }

    try {
      const channelImageUrl: string = await localGetImageUrl(channelImageId, CHANNEL_IMAGE_WIDTH, CHANNEL_IMAGE_HEIGHT, Luminosity.Light, signal);
      this.setState({ channelImageUrl });

      // Notify media controller
      MediaController.setImage(channelImageUrl, 'image/png', CHANNEL_IMAGE_WIDTH, CHANNEL_IMAGE_HEIGHT);

      if (channelImageUrl === '' && currentItem?.locType === NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD && programMetadata) {
        // No channel image: try to load item image
        return this.loadImageFromMetadata(programMetadata, seriesMetadata);
      }

      return Promise.resolve();
    } catch (error) {
      return ignoreIfAborted(signal, error);
    }
  };

  // Trailer
  openTrailerItem = (programMetadata: NETGEM_API_V8_METADATA_PROGRAM, seriesMetadata: NETGEM_API_V8_METADATA_SERIES | null): Promise<void> => {
    const { streamPriorities } = this.props;

    const trailer: NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_PARAM | null = getTrailer(streamPriorities, programMetadata, seriesMetadata);

    if (!trailer) {
      return this.handleClose({ errorCode: VIDEOPLAYER_ERRORS.VS997NoTrailerError, errorMsg: Localizer.localize('player.trailer.not_found') });
    }

    this.showOverlay();

    const { path: url, type } = trailer;

    this.setDebugInfoUrls(url);

    this.getSubtitlesFromMetadata(seriesMetadata ?? programMetadata);

    this.setState(
      produce((draft) => {
        draft.isTrailer = true;
        draft.programInfoLoadStatus = ProgramInfoLoadStatus.Loaded;
        draft.programMetadata = programMetadata;
        draft.seriesEpisodeText = formatSeasonEpisodeNbr(programMetadata);
        draft.seriesMetadata = seriesMetadata;
        draft.title = getTitle(seriesMetadata || programMetadata, Localizer.language);
        draft.videoStreamData = {
          type,
          url,
        };
      }),
      () => this.startIfReady(() => this.handleClose({ errorCode: VIDEOPLAYER_ERRORS.VS998InternalError })),
    );

    return Promise.resolve();
  };

  handleVodPlayError = (
    error: BO_API_ERROR_TYPE | Error,
    item: NETGEM_API_V8_FEED_ITEM,
    programMetadata: NETGEM_API_V8_METADATA_PROGRAM,
    seriesMetadata: NETGEM_API_V8_METADATA_SERIES | null,
    vtiId: number,
  ): Promise<void> => {
    let status: ?(string | number) = null;
    let errorMsg: string | null = null;
    let maxNbStreams = 1;

    if (error instanceof PlayerError) {
      ({ code: status, message: errorMsg } = error);
    } else if (error instanceof CustomNetworkError) {
      if (isInvalidTokenError(error)) {
        // Nothing to do: app will restart
        return this.handleClose({ errorCode: NO_ERROR_CODE });
      }

      const {
        networkError: { result },
      } = error;

      if (result) {
        ({ code: status, message: errorMsg } = result);
        const maxNbStreamsStr = result.variables?.maxNbStreams;
        maxNbStreams = typeof maxNbStreamsStr === 'number' ? Number(maxNbStreamsStr) : 1;
      }
    }

    if (status === null) {
      ({ errorMsg, status } = buildBOErrorResponse(null, error));
    }

    let isLargeNotificationNeeded = false;
    if (status === BO_STREAM_MAX_CONCURRENT_STREAMS_REACHED) {
      // BO error code when max number of concurrent streams being played is reached
      errorMsg = Localizer.localize('player.errors.max_concurrent_streams_reached', { count: maxNbStreams });
      isLargeNotificationNeeded = true;
    } else if (status === BO_STREAM_NO_SVOD_CONTENT_RIGHT) {
      // BO error code when no right has been found
      errorMsg = Localizer.localize('player.errors.missing_right');
    } else if (status === BO_STREAM_INVALID_USER_IP) {
      // BO error code when user IP is geo locked
      errorMsg = Localizer.localize('player.errors.content_unavailable_in_country');
    } else if (status === BO_STREAM_DEVICE_NOT_FOUND || status === BO_INVALID_JWT_V2 || status === BO_INVALID_APPLICATION_V2 || status === BO_INVALID_SUBSCRIBER_V2) {
      // Nothing to do: app will restart
      return this.handleClose({ errorCode: NO_ERROR_CODE });
    }

    SentryWrapper.error({
      breadcrumbs: ['Function openVodItem', 'playFunc rejected'],
      context: {
        item: JSON.stringify(item),
        programMetadata: JSON.stringify(programMetadata),
        seriesMetadata: JSON.stringify(seriesMetadata),
        vtiId,
      },
      error: error instanceof Error ? error : undefined,
      message: errorMsg ?? 'playFunc failure',
      tagName: SentryTagName.Component,
      tagValue: SentryTagValue.Player,
    });

    return this.handleClose({ errorCode: status?.toString(), errorMsg, isLargeNotificationNeeded });
  };

  // VOD
  openVodItem = async (
    item: NETGEM_API_V8_FEED_ITEM,
    programMetadata: NETGEM_API_V8_METADATA_PROGRAM,
    seriesMetadata: NETGEM_API_V8_METADATA_SERIES | null,
    vtiId: number | null,
    viewingHistoryId: string | null,
    locationId: string | null,
    forcedResumePosition?: number,
  ): Promise<void> => {
    const { localCreateVodStreamFromId, localCreateSvodStreamFromId, playerItem, purchaseList } = this.props;
    const {
      abortController: { signal },
    } = this;

    // Use given location Id (which tells if the video is a TVOD or an EST, and if not defined, use the first location's
    const {
      selectedLocation: { id },
    } = item;
    const localLocationId = locationId ?? id;

    if (!playerItem || !localLocationId) {
      return this.handleClose({ errorCode: VIDEOPLAYER_ERRORS.VS998InternalError });
    }

    const { distributorId } = playerItem;

    if (!distributorId) {
      SentryWrapper.error({
        breadcrumbs: ['Function openVodItem'],
        context: {
          item: JSON.stringify(item),
          programMetadata: JSON.stringify(programMetadata),
          seriesMetadata: JSON.stringify(seriesMetadata),
          vtiId,
        },
        message: 'Missing distributorId',
        tagName: SentryTagName.Component,
        tagValue: SentryTagValue.Player,
      });
      logWarning('Cannot open player without distributorId');

      return this.handleClose({ errorCode: VIDEOPLAYER_ERRORS.VS998InternalError });
    }

    if (!vtiId) {
      SentryWrapper.error({
        breadcrumbs: ['Function openVodItem'],
        context: {
          distributorId,
          item: JSON.stringify(item),
          programMetadata: JSON.stringify(programMetadata),
          seriesMetadata: JSON.stringify(seriesMetadata),
        },
        message: 'Missing vtiId',
        tagName: SentryTagName.Component,
        tagValue: SentryTagValue.Player,
      });
      logWarning('Cannot open player without vtiId');

      return this.handleClose({ errorCode: VIDEOPLAYER_ERRORS.VS998InternalError });
    }

    this.showOverlay();
    this.isPlayStarted = true;
    this.distributorId = distributorId;

    this.setState(
      produce((draft) => {
        draft.currentItem = item;

        if (typeof forcedResumePosition === 'number') {
          draft.forcedResumePosition = forcedResumePosition;
        }
      }),
    );

    const createStream = typeof forcedResumePosition === 'number' || hasBeenPurchased(purchaseList, distributorId, vtiId) ? localCreateVodStreamFromId : localCreateSvodStreamFromId;

    /*
     * In case a stream was not correctly closed, we receive a 1002 error when trying to play it again.
     * So, in this case, we try once to kill all open streams (just like at startup) and try again to create the stream
     */
    const createStreamWithRetry = async (isRetry: boolean): Promise<BO_API_CREATE_STREAM_TYPE> => {
      const { localStopOpenStreams } = this.props;

      try {
        return await createStream(distributorId, vtiId, signal);
      } catch (error) {
        // BO_API_ERROR_TYPE | CustomNetworkError | PlayerError
        if (error instanceof CustomNetworkError && !isRetry && error.getCustomCode() === BO_STREAM_MAX_CONCURRENT_STREAMS_REACHED) {
          await localStopOpenStreams(distributorId, signal);
          signal.throwIfAborted();
          return createStreamWithRetry(true);
        }

        throw error;
      }
    };

    try {
      const createdStream: BO_API_CREATE_STREAM_TYPE = await createStreamWithRetry(false);
      return this.startVideofuturStream(createdStream.stream, localLocationId, programMetadata, seriesMetadata, viewingHistoryId);
    } catch (error) {
      // BO_API_ERROR_TYPE | CustomNetworkError | PlayerError
      return ignoreIfAborted(signal, error, () => this.handleVodPlayError(error, item, programMetadata, seriesMetadata, vtiId));
    }
  };

  getSubtitlesFromMetadata = (metadata: NETGEM_API_V8_METADATA_SCHEDULE_LOCATION | NETGEM_API_V8_METADATA) => {
    const { externalSubtitles } = this.state;
    const { playbackUrls, trailerUrl } = metadata;

    if (externalSubtitles.length > 0) {
      // Subtitles already set (at stream creation)
      return;
    }

    let start: NETGEM_API_V8_URL_DEFINITION | null = null;
    if (playbackUrls && playbackUrls.length > 0) {
      // Schedule location
      [{ start }] = playbackUrls;
    } else if (trailerUrl) {
      // Program metadata
      ({ start } = trailerUrl);
    } else {
      return;
    }

    const { params } = start;
    const videostreams = params?.find((i) => i.name === 'videostreams');

    const propertiesArg = videostreams?.value.args.find((i) => ((i: any): NETGEM_API_V8_METADATA_LOCATION_VIDEO_STREAM_PARAM).properties);
    if (!propertiesArg) {
      return;
    }

    const { properties } = ((propertiesArg: any): NETGEM_API_V8_METADATA_LOCATION_VIDEO_STREAM_PARAM);
    if (!properties) {
      return;
    }

    const { subtitles } = properties;
    if (!subtitles) {
      return;
    }

    this.setState(
      produce((draft) => {
        draft.externalSubtitles = filterExternalSubtitles(subtitles);
      }),
    );
  };

  loadVodLocationMetadata = async (locationId: string): Promise<void> => {
    const { localSendV8MetadataLocationRequest } = this.props;
    const { videoStreamData } = this.state;
    const {
      abortController: { signal },
    } = this;

    try {
      // NETGEM_API_V8_REQUEST_RESPONSE
      const {
        result: { location: locationMetadata },
      } = await localSendV8MetadataLocationRequest(locationId, signal);

      this.setState(
        produce((draft) => {
          draft.location = locationMetadata;
        }),
      );
      this.getSubtitlesFromMetadata(locationMetadata);
      return this.startVideoInternal(videoStreamData, () => this.handleClose({ errorCode: VIDEOPLAYER_ERRORS.VS998InternalError }));
    } catch (error) {
      return ignoreIfAborted(signal, error);
    }
  };

  // VOD
  startVideofuturStream = async (
    stream: BO_API_STREAM_TYPE,
    locationId: string,
    programMetadata: NETGEM_API_V8_METADATA_PROGRAM,
    seriesMetadata: NETGEM_API_V8_METADATA_SERIES | null,
    viewingHistoryId: string | null,
  ): Promise<void> => {
    // Following method creates 'videoStreamData'
    const newState = await this.initializeVideofuturData(stream, programMetadata, seriesMetadata, locationId, viewingHistoryId);

    if (!newState) {
      return;
    }

    this.setState(
      produce((draft) => {
        draft.endMargin = newState.endMargin;
        draft.externalSubtitles = newState.externalSubtitles;
        draft.isBuffering = newState.isBuffering;
        draft.isVideofuturAsset = newState.isVideofuturAsset;
        draft.locationId = newState.locationId;
        draft.programInfoLoadStatus = newState.programInfoLoadStatus;
        draft.programMetadata = newState.programMetadata;
        draft.realEnd = newState.realEnd;
        draft.realStart = newState.realStart;
        draft.seriesEpisodeText = newState.seriesEpisodeText;
        draft.seriesMetadata = newState.seriesMetadata;
        draft.startMargin = newState.startMargin;
        draft.title = newState.title;
        draft.videoStreamData = newState.videoStreamData;
        draft.viewingHistoryId = newState.viewingHistoryId;
        draft.vtiId = newState.vtiId;
      }),
      () => this.loadVodLocationMetadata(locationId),
    );
  };

  // VOD
  initializeVideofuturData = async (
    stream: BO_API_STREAM_TYPE,
    programMetadata: NETGEM_API_V8_METADATA_PROGRAM,
    seriesMetadata: NETGEM_API_V8_METADATA_SERIES | null,
    locationId: string,
    viewingHistoryId: string | null,
  ): Promise<PlayerStateInitializationType | null> => {
    const { grantedTicket, id, subtitles, vtiId } = stream;

    this.isRestartingSession = false;

    if (!grantedTicket) {
      await this.handleClose({ errorCode: VIDEOPLAYER_ERRORS.VS998InternalError });
      return null;
    }

    const { contentUrl: url, fairplayCertificateUrl, fairplayContentKeySystem, laUrl, urlLifecycle } = grantedTicket;
    const drm: Undefined<Drm> = getFirstSupportedDrm();

    // Check if keep-alive is enabled
    const redirectUrl: string | null = await this.initializeKeepAliveIfRequired(urlLifecycle, url);

    const videoStreamData: VideoPlayerInitData = {
      drm,
      fairplayCertificateUrl,
      fairplayContentKeySystem,
      laUrl,
      type: fairplayCertificateUrl ? StreamType.HLS : StreamType.DASH,
      url: redirectUrl ?? url,
    };

    this.setDebugInfoUrls(videoStreamData.url, videoStreamData.laUrl);

    this.currentVideofuturStreamId = id > 0 ? id : null;

    // Data collection (EST, SVOD, TVOD)
    const { duration } = programMetadata;
    this.startDataCollection(programMetadata, locationId, duration);

    return {
      endMargin: 0,
      externalSubtitles: filterExternalSubtitles(subtitles),
      isBuffering: true,
      isVideofuturAsset: true,
      locationId,
      programInfoLoadStatus: ProgramInfoLoadStatus.Loaded,
      programMetadata,
      realEnd: 0,
      realStart: 0,
      seriesEpisodeText: formatSeasonEpisodeNbr(programMetadata),
      seriesMetadata,
      startMargin: 0,
      title: getTitle(programMetadata, Localizer.language),
      videoStreamData,
      viewingHistoryId,
      vtiId,
    };
  };

  initializeCatchupPlayer = async (locationId: string): Promise<void> => {
    const { localSendV8MetadataLocationRequest } = this.props;
    const {
      abortController: { signal },
    } = this;

    this.checkEndFunction = this.checkEnd;
    this.setState({ programInfoLoadStatus: ProgramInfoLoadStatus.Loaded });

    try {
      // NETGEM_API_V8_REQUEST_RESPONSE
      const {
        result: { location },
      } = await localSendV8MetadataLocationRequest(locationId, signal);

      this.setState(
        produce((draft) => {
          draft.location = location;
        }),
      );
      this.getSubtitlesFromMetadata(location);
      await this.launchPlayer(location);
    } catch (error) {
      await ignoreIfAborted(signal, error, () => {
        this.setState({ videoStreamData: null });
        this.handleClose({ errorCode: VIDEOPLAYER_ERRORS.VP001MetadataLocationRequestError });
      });
    }
  };

  initializeRecordingPlayer = async (locationId: string): Promise<void> => {
    const { localSendV8RecordingsMetadataRequest } = this.props;
    const {
      abortController: { signal },
    } = this;

    this.checkEndFunction = this.checkEnd;
    this.setState({ programInfoLoadStatus: ProgramInfoLoadStatus.Loaded });

    try {
      // NETGEM_API_V8_REQUEST_RESPONSE
      const {
        result: { location },
      } = await localSendV8RecordingsMetadataRequest(locationId, signal);

      this.setState(
        produce((draft) => {
          draft.location = location;
        }),
      );
      await this.launchPlayer(location);
    } catch (error) {
      await ignoreIfAborted(signal, error, () => {
        this.setState({ videoStreamData: null });
        this.handleClose({ errorCode: VIDEOPLAYER_ERRORS.VP003MetadataRecordingsRequestError });
      });
    }
  };

  loadLiveItemLocationMetadata = async (currentItem: NETGEM_API_V8_FEED_ITEM): Promise<void> => {
    const { localSendV8MetadataLocationRequest } = this.props;
    const {
      abortController: { signal },
    } = this;

    const {
      locType,
      selectedLocation: { id: locationId },
      selectedProgramId,
    } = currentItem;

    // Live play could have been originated from a live tile or a record tile
    if (locType !== NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT || selectedProgramId.startsWith(FAKE_EPG_LIVE_PREFIX) || selectedProgramId.startsWith(FAKE_LIVE_CHANNEL_PREFIX)) {
      return;
    }

    // For real live program, location is needed to enable the record button
    try {
      // NETGEM_API_V8_REQUEST_RESPONSE
      const {
        result: { location },
      } = await localSendV8MetadataLocationRequest(locationId, signal);

      this.setState(
        produce((draft) => {
          draft.location = location;
        }),
        () => this.checkBlackOut(location),
      );
    } catch (error) {
      await ignoreIfAborted(signal, error, () => this.handleClose({ errorCode: VIDEOPLAYER_ERRORS.VP001MetadataLocationRequestError }));
    }
  };

  // Live, catchup, recording
  initializePlayer = (): Promise<void> => {
    const { currentItem, locationStatus, locationId, programMetadata } = this.state;

    if (!currentItem || (!programMetadata && locationStatus !== WebAppHelpersLocationStatus.Live) || !locationId) {
      return this.handleClose({ errorCode: VIDEOPLAYER_ERRORS.VS998InternalError });
    }

    // Start data collection for live, catchup and recording

    const {
      selectedLocation: { duration, scheduledEventDuration },
    } = currentItem;
    this.startDataCollection(programMetadata, locationId, duration ?? scheduledEventDuration);

    if (locationStatus === WebAppHelpersLocationStatus.Recording) {
      // Recording
      return this.initializeRecordingPlayer(locationId);
    } else if (locationStatus === WebAppHelpersLocationStatus.Catchup) {
      // Catchup
      return this.initializeCatchupPlayer(locationId);
    } else if (locationStatus === WebAppHelpersLocationStatus.Live) {
      // Live
      return this.initializeLivePlayer(ProgramInfoLoadStatus.Loaded);
    }

    return this.handleClose({ errorCode: VIDEOPLAYER_ERRORS.VS998InternalError });
  };

  // Start playing a live program
  initializeLivePlayer = (programInfoLoadStatus: ProgramInfoLoadStatus): Promise<void> => {
    const { channel } = this.state;

    if (!channel) {
      return this.handleClose({ errorCode: VIDEOPLAYER_ERRORS.VS998InternalError });
    }

    try {
      const { errorCode, initData } = getVideoStreamDataFromChannel(channel);
      if (errorCode !== undefined) {
        return this.handleClose({ errorCode });
      }

      this.isPlayStarted = false;

      this.setState(
        produce((draft) => {
          draft.isBuffering = true;
          draft.playheadPosition = 0;
          draft.programInfoLoadStatus = programInfoLoadStatus;
          draft.timeshift = 0;
          draft.videoStreamData = initData;
        }),
        () => this.startIfReady(this.displayChannelNotAvailableMsg),
      );

      return Promise.resolve();
    } catch (error) {
      return this.handleClose({ errorCode: VIDEOPLAYER_ERRORS.VS999StreamUnknownError });
    }
  };

  // Start playing for catchup, recording
  launchPlayer = (location: NETGEM_API_V8_METADATA_SCHEDULE_LOCATION): Promise<void> => {
    const { state, streamPriorities } = this.props;
    const { currentItem, locationStatus } = this.state;
    const {
      endMargin: locEndMargin,
      metrics,
      playbackUrls,
      tracking,
      startMargin: locStartMargin,
      userViewEndOffset: locUserViewEndOffset,
      userViewStartOffset: locUserViewStartOffset,
      duration,
    } = location;
    if (!playbackUrls || playbackUrls.length === 0) {
      // No playback URL
      SentryWrapper.error({
        breadcrumbs: ['Function launchPlayer', 'playback URL error'],
        context: { location: JSON.stringify(location) },
        message: 'Missing playback URL',
        tagName: SentryTagName.Component,
        tagValue: SentryTagValue.Player,
      });

      return this.handleClose({ errorCode: VIDEOPLAYER_ERRORS.VS002StreamNotFound });
    }

    const [{ start: playbackUrlDefinition }] = playbackUrls;
    const { allStreams, errorCode } = getStreamsFromPlaybackUrls(streamPriorities, playbackUrls);

    if (!allStreams || errorCode) {
      // No supported stream or DRM found
      SentryWrapper.error({
        breadcrumbs: ['Function launchPlayer', 'stream error'],
        context: { location: JSON.stringify(location) },
        message: `Error looking for stream: ${errorCode ?? VIDEOPLAYER_ERRORS.VS999StreamUnknownError}`,
        tagName: SentryTagName.Component,
        tagValue: SentryTagValue.Player,
      });

      return this.handleClose({ errorCode });
    }

    const [stream] = allStreams;

    // Margins and user view offsets
    const startMargin = getIso8601DurationInSeconds(locStartMargin);
    const endMargin = getIso8601DurationInSeconds(locEndMargin);
    const userViewStartOffset = getIso8601DurationInSeconds(locUserViewStartOffset);
    const userViewEndOffset = getIso8601DurationInSeconds(locUserViewEndOffset);
    const realStart = Math.max(startMargin, userViewStartOffset);
    const realEnd = Math.max(endMargin, userViewEndOffset);

    // Total duration = program duration + start margin + end margin
    const totalDuration = getIso8601DurationInSeconds(duration);

    const videoStreamData: VideoPlayerInitData = {
      channelId: location.channelId,
      drm: stream.drm,
      laUrl: stream.laUrl,
      manifestUpdatePeriod: 0,
      ntgEntitlement: stream.ntgEntitlement,
      trackingStart: tracking?.start,
      type: stream.type,
      url: generateApiUrl(playbackUrlDefinition, { videostreams: stream.path }, state),
    };

    // Check if we're trying to play a recording in progress
    if (currentItem && locationStatus === WebAppHelpersLocationStatus.Recording) {
      const {
        selectedLocation: { scheduledEventDuration, scheduledEventStartDate },
      } = currentItem;
      const now = AccurateTimestamp.nowInSeconds();
      const startDate = getIso8601DateInSeconds(scheduledEventStartDate);
      const endDate = startDate + getIso8601DurationInSeconds(scheduledEventDuration);

      if (realStart <= now && now < endDate + endMargin) {
        this.setState({ contentType: ContentType.LiveRecording });
      }
    }

    this.setState(
      produce((draft) => {
        draft.endMargin = endMargin;
        draft.isBuffering = true;
        draft.locationMetrics = metrics;
        draft.realEnd = realEnd;
        draft.realStart = realStart;
        draft.startMargin = startMargin;
        draft.totalDuration = totalDuration;
        draft.userViewEndOffset = userViewEndOffset;
        draft.userViewStartOffset = userViewStartOffset;
        draft.videoStreamData = videoStreamData;
      }),
      () => this.startIfReady(() => this.handleClose({ errorCode: VIDEOPLAYER_ERRORS.VS001NoStreamSupported })),
    );

    return Promise.resolve();
  };

  setTimeshiftSwitch = (isSwitching: boolean) => {
    this.setState({ isTimeshiftSwitching: isSwitching });
  };

  startDataCollection = (programMetadata: ?NETGEM_API_V8_METADATA_PROGRAM, locationId: string, isoDuration: ?string) => {
    const { dataCollectionSettings } = this;

    if (!dataCollectionSettings || !programMetadata) {
      // Data collection disabled
      return;
    }

    const locType = getLocationType(locationId);
    const { frequency, isEnabled, rampUp, streamTypes } = dataCollectionSettings;

    if (!isEnabled || !streamTypes || !locType) {
      // Data collection disabled for player state or not enough settings
      return;
    }

    let streamType: Undefined<DataCollectionStream> = undefined;
    switch (locType) {
      case NETGEM_API_V8_ITEM_LOCATION_TYPE_CATCHUP:
        streamType = DataCollectionStream.Catchup;
        break;
      case NETGEM_API_V8_ITEM_LOCATION_TYPE_EST:
        streamType = DataCollectionStream.EST;
        break;
      case NETGEM_API_V8_ITEM_LOCATION_TYPE_RECORDING:
        streamType = DataCollectionStream.Recording;
        break;
      case NETGEM_API_V8_ITEM_LOCATION_TYPE_SCHEDULEDEVENT:
        streamType = DataCollectionStream.Live;
        break;
      case NETGEM_API_V8_ITEM_LOCATION_TYPE_SVOD:
        streamType = DataCollectionStream.SVOD;
        break;
      case NETGEM_API_V8_ITEM_LOCATION_TYPE_TVOD:
        streamType = DataCollectionStream.TVOD;
        break;
      default:
        return;
    }

    if (streamTypes.indexOf(streamType) === -1) {
      // Stream type not to be collected
      return;
    }

    const { id: programId } = programMetadata;

    this.setState(
      produce((draft) => {
        draft.dataCollectionStartTime = AccurateTimestamp.nowInSeconds();
        draft.dataCollectionMessage = {
          locationId,
          playbackDuration: 0,
          position: 0,
          programDuration: getIso8601DurationInSeconds(isoDuration),
          programId,
          sessionDuration: 0,
          sessionGuid: crypto.randomUUID(),
          state: DataCollectionPlayerState.Start,
          streamType,
        };
      }),
      () => this.finalizeDataCollectionStart(rampUp, frequency),
    );
  };

  finalizeDataCollectionStart = (rampUp?: Array<number>, frequency?: number) => {
    const { dataCollectionRampUpTimers } = this;

    if (rampUp) {
      rampUp.forEach((t) => dataCollectionRampUpTimers.push(setTimeout(this.sendCurrentPlayerState, t)));
    }

    if (typeof frequency === 'number' && frequency > -1) {
      this.dataCollectionSamplingTimer = setInterval(this.sendCurrentPlayerState, frequency);
    }
  };

  stopProgramDataCollection = (useLastPosition: boolean) => {
    this.sendCurrentPlayerState(DataCollectionPlayerState.Stop, useLastPosition);
    this.setState({ dataCollectionStartTime: AccurateTimestamp.nowInSeconds() });
  };

  // Happens when switching from live to timeshift (and reverse) inside the same program
  restartProgramDataCollection = () => {
    this.stopProgramDataCollection(true);
    this.sendCurrentPlayerState(DataCollectionPlayerState.Start);
  };

  updateProgramDataCollection = (programMetadata: NETGEM_API_V8_METADATA_PROGRAM, locationId: string | null, isoDuration?: string) => {
    const { dataCollectionMessage } = this.state;

    if (!dataCollectionMessage || !locationId || !isoDuration) {
      // Not enabled or problem somewhere
      return;
    }

    const { id } = programMetadata;

    this.setState(
      produce((draft) => {
        draft.dataCollectionMessage = {
          locationId,
          playbackDuration: 0,
          position: 0,
          programDuration: getIso8601DurationInSeconds(isoDuration),
          programId: id,
          sessionDuration: 0,
          sessionGuid: crypto.randomUUID(),
        };
      }),
      () => this.sendCurrentPlayerState(DataCollectionPlayerState.Start),
    );
  };

  stopDataCollection = () => {
    const { dataCollectionRampUpTimers } = this;

    while (dataCollectionRampUpTimers.length > 0) {
      clearTimeout(dataCollectionRampUpTimers.pop());
    }

    if (this.dataCollectionSamplingTimer) {
      clearInterval(this.dataCollectionSamplingTimer);
      this.dataCollectionSamplingTimer = null;
    }

    this.setState({
      dataCollectionMessage: null,
      dataCollectionStartTime: 0,
    });
  };

  sendCurrentPlayerState = (state?: DataCollectionPlayerState, useLastPosition: ?boolean, errorCode: ?string) => {
    const { dataCollectionMessage } = this.state;

    if (!dataCollectionMessage) {
      return;
    }

    this.setState(
      produce((draft) => {
        // Without line below, Flow complains about draft.dataCollectionMessage that could be null
        const dcMsg = draft.dataCollectionMessage;

        if (dcMsg === null) {
          return;
        }

        const now = AccurateTimestamp.nowInSeconds();

        // Update properties
        const localChannelId = draft.channel?.epgid ?? draft.location?.channelId;
        if (localChannelId) {
          dcMsg.channelId = localChannelId;
        }
        dcMsg.sessionDuration = now - draft.dataCollectionStartTime;

        if (draft.dataCollectionLastPlaybackTime > 0) {
          dcMsg.playbackDuration = (dcMsg.playbackDuration ?? 0) + now - draft.dataCollectionLastPlaybackTime;

          // Check for abnormal duration and send error to Sentry if needed
          this.checkPlaybackDuration(dcMsg, now, draft.dataCollectionLastPlaybackTime);
        }

        if (
          (state || (dcMsg.state !== DataCollectionPlayerState.Start && dcMsg.state !== DataCollectionPlayerState.Play)) &&
          state !== DataCollectionPlayerState.Start &&
          state !== DataCollectionPlayerState.Play
        ) {
          draft.dataCollectionLastPlaybackTime = 0;
        } else {
          draft.dataCollectionLastPlaybackTime = now;
        }

        if (draft.contentType === ContentType.Live) {
          // Live with or without timeshift
          dcMsg.streamType = draft.timeshift <= TIMESHIFT_THRESHOLD ? DataCollectionStream.Live : DataCollectionStream.Timeshift;
          if (!useLastPosition && draft.currentItem) {
            const { startTime } = draft.currentItem;
            dcMsg.position = now - draft.timeshift - startTime;
          }
        } else {
          // Catchup, finished or in-progress recording, VOD, trailer
          dcMsg.position = Math.round(draft.playheadPosition - draft.realStart);
        }

        if (dcMsg.state === DataCollectionPlayerState.Start && typeof dcMsg.position === 'number' && dcMsg.position < 0) {
          // Special case at startup
          dcMsg.position = 0;
        }

        if (state) {
          // Update state only if specified, otherwise keep current state
          dcMsg.state = state;
        }

        if (errorCode) {
          dcMsg.errorDetails = errorCode;
        }

        Messenger.emit(DataCollectionMessage.PlayerState, dcMsg);

        if (dcMsg.state === DataCollectionPlayerState.Start) {
          // Start state is only sent once
          dcMsg.state = DataCollectionPlayerState.Play;
        }
      }),
    );
  };

  checkPlaybackDuration = (dataCollectionMessage: DATA_COLLECTION_INTERNAL_MESSAGE, now: number, dataCollectionLastPlaybackTime: number) => {
    const { playbackDuration, programDuration } = dataCollectionMessage;
    const { playerSessionStart } = this;

    if (typeof playbackDuration !== 'number' || typeof programDuration !== 'number' || programDuration === 0 || playbackDuration <= programDuration * PLAYBACK_DURATION_ERROR_THRESHOLD) {
      // Duration is OK
      return;
    }

    // Playback duration exceeds program duration by at least 10%
    SentryWrapper.error({
      breadcrumbs: ['Function sendCurrentPlayerState', 'Playback duration too high'],
      context: {
        dataCollectionLastPlaybackTime,
        dataCollectionMessage: JSON.stringify(dataCollectionMessage),
        now,
        playerSessionDuration: AccurateTimestamp.now() - playerSessionStart,
        timeOrigin: AccurateTimestamp.origin(),
      },
      message: `Playback duration (${playbackDuration}) is greater than program duration (${programDuration})`,
      tagName: SentryTagName.Component,
      tagValue: SentryTagValue.Player,
    });
  };

  onFullScreenChange = () => {
    const { fullscreenElement } = fscreen;

    if (fullscreenElement === null) {
      // Exited fullscreen
      this.setState({ isInFullscreen: false });
      this.showOverlay();
    } else {
      // Entered fullscreen
      this.setState({ isInFullscreen: true });
    }
  };

  playbackPaused = () => {
    const { skipping } = this.state;
    const { mediametrie } = this;

    this.setState(
      produce((draft) => {
        draft.debugInfo.state = VIDEOPLAYER_DEBUG.StatePaused;
      }),
    );

    if (skipping !== SkippingKind.None) {
      return;
    }

    if (mediametrie) {
      mediametrie.notifyPause();
    }

    MediaController.setPausedState();

    this.setState({ isPlaying: false });
    this.showOverlay();
  };

  playbackPlaying = () => {
    const { skipping } = this.state;
    const { mediametrie } = this;

    this.setState(
      produce((draft) => {
        draft.debugInfo.state = VIDEOPLAYER_DEBUG.StatePlaying;
      }),
    );

    if (skipping !== SkippingKind.None) {
      return;
    }

    if (mediametrie) {
      mediametrie.notifyPlay();
    }

    MediaController.setPlayingState();

    this.setState({
      isControllerEnabled: true,
      isPlaying: true,
    });
  };

  playbackEnded = (): Promise<void> => {
    this.setState(
      produce((draft) => {
        draft.debugInfo.state = VIDEOPLAYER_DEBUG.StateEnded;
      }),
    );

    return this.handleClose({ errorCode: NO_ERROR_CODE });
  };

  playbackTimeUpdated = (time: number, timeshift?: number) => {
    const { contentType } = this.state;
    const { videoElement } = this;

    if (videoElement) {
      this.setState(
        produce((draft) => {
          draft.bufferedTimeRanges = videoElement.buffered;
        }),
      );
    }

    const isPlayheadPositionFallback = time === 0 && contentType !== ContentType.Static;
    const playheadPosition = isPlayheadPositionFallback ? AccurateTimestamp.nowInSeconds() : time;

    this.setState(
      produce((draft) => {
        draft.debugInfo.isPlayheadPositionFallback = isPlayheadPositionFallback;
        draft.debugInfo.time = Math.floor(playheadPosition);
        draft.playheadPosition = playheadPosition;
        draft.timeshift = typeof timeshift === 'number' ? timeshift : 0;
      }),
      this.checkCurrentPlayedItem,
    );

    this.checkResumeState();
  };

  checkResumeState = () => {
    const { resumePosition, resumeState } = this.state;

    if (resumeState !== ResumeState.Seeking) {
      return;
    }

    this.setState({ resumeState: ResumeState.Done }, () => {
      this.seek(resumePosition, true);
    });
  };

  // Called with a Shaka Player error code
  playerError = (category: number, code: number, mediaErrorCode: number, errorMsg?: string, isForbidden?: boolean): Promise<void> => {
    /*
     * Shaka Player categories:
     *   1: network stack
     *   2: parsing text streams
     *   3: parsing or processing audio or video streams
     *   4: parsing the manifest
     *   5: related to streaming
     *   6: related to DRM
     *   7: miscellaneous errors
     *   8: related to cast
     *   9: database storage (offline)
     *  10: related to ad insertion
     *
     * HTML media error codes:
     *   1: play aborted by user  MEDIA_ERR_ABORTED
     *   2: network error         MEDIA_ERR_NETWORK
     *   3: decoding error        MEDIA_ERR_DECODE
     *   4: unsupported format    MEDIA_ERR_SRC_NOT_SUPPORTED
     *
     * All other error codes are listed in shakaTypes.js
     */

    const { playerItem } = this.props;
    const { title, videoStreamData } = this.state;
    const { keepAlive, isRestartingSession } = this;

    // Broadpeak keep-alive safeguard
    if (keepAlive && isForbidden) {
      if (isRestartingSession) {
        return Promise.resolve();
      }
      keepAlive.stop();
      return this.restartSession();
    }

    const playerVersion = this.videoPlayer?.getVersion() ?? 'unknown';

    if (shouldErrorBeIgnored(code)) {
      // Player exited while starting: silently close
      return this.handleClose({ errorCode: NO_ERROR_CODE });
    }

    if (!playerItem) {
      // Offline content
      return this.handleClose({ errorCode: VIDEOPLAYER_ERRORS.VS994OfflineContentError });
    }

    const {
      item: {
        locType: locationType,
        selectedLocation: { channelId, id: locationId },
        selectedProgramId: programId,
        seriesId,
      },
    } = playerItem;

    SentryWrapper.error({
      breadcrumbs: ['Function playerError', getShakaErrorCategoryAsText(category), getShakaErrorCodeAsText(code), getHtmlMediaErrorText(mediaErrorCode)],
      context: {
        channelId,
        drm: videoStreamData?.drm ?? 'unknown',
        locationId,
        locationType,
        playerVersion,
        programId,
        seriesId,
        streamType: videoStreamData?.type ?? 'unknown',
        streamUrl: videoStreamData?.url ?? 'unknown',
        title,
      },
      message: `[${category}.${code}.${mediaErrorCode}] ${errorMsg ?? 'Player error'}`,
      tagName: SentryTagName.Component,
      tagValue: SentryTagValue.Player,
    });

    return this.handleClose({ errorCode: `${category}.${code}.${mediaErrorCode}`, errorMsg: Localizer.localize('player.errors.generic_error') });
  };

  // Called when a VOD play triggers a MEDIA_ERR_DECODE error on Safari
  safariVodWorkaround = (): Promise<void> => this.handleClose({ isSafariWorkaround: true });

  bufferLoaded = () => {
    this.setState({ isBuffering: false });
  };

  buffering = () => {
    this.setState({ isBuffering: true });
  };

  liveBufferLengthUpdated = (liveBufferLength: number) => {
    this.setState({ liveBufferLength });
  };

  toggleDebugInfoMetrics = (isDebugOverlayVisible: boolean) => {
    const { localUpdateSetting } = this.props;

    localUpdateSetting(Setting.AutoOpenPlayerDebugPanel, isDebugOverlayVisible);

    if (isDebugOverlayVisible) {
      this.updateDebugInfoMetrics();
    } else {
      this.resetDebugInfoMetricsTimer();
    }
  };

  updateDebugInfoMetrics = () => {
    const { videoElement, videoPlayer } = this;

    if (!videoElement || !videoPlayer) {
      return;
    }

    const commonMetrics = getCommonMetrics(videoElement);

    /*
     * When playing HLS+Fairplay content, Shaka Player let native HTML player handle everything.
     * In this particular case, shakaPlayer.getVariantTracks() and video.videoTracks only return 1 track (with empty values)
     * Hence, the debug panel does not offer much information.
     * Shaka Player can handle HLS on its own, but only without any DRM.
     *
     * More on this at: https://github.com/shaka-project/shaka-player/issues/2802
     */
    const specificMetrics = videoPlayer.getMetrics();

    if (!specificMetrics) {
      this.startDebugInfoMetricsTimer();
      return;
    }

    this.setState(
      produce((draft) => {
        draft.debugInfo.bufferChunkCount = commonMetrics.bufferChunkCount;
        draft.debugInfo.bufferLength = commonMetrics.bufferLength;
        draft.debugInfo.elementHeight = commonMetrics.elementHeight;
        draft.debugInfo.elementWidth = commonMetrics.elementWidth;
        draft.debugInfo.playbackRate = commonMetrics.playbackRate;
        draft.debugInfo.videoHeight = commonMetrics.videoHeight;
        draft.debugInfo.videoWidth = commonMetrics.videoWidth;
        draft.debugInfo.shakaMetrics = specificMetrics?.shakaMetrics;
      }),
      this.startDebugInfoMetricsTimer,
    );
  };

  initializeDebugInfo = (playerDuration: number) => {
    const { videoPlayer } = this;

    if (!videoPlayer) {
      return;
    }

    this.setState(
      produce((draft) => {
        draft.debugInfo.playerName = videoPlayer.getName();
        draft.debugInfo.playerVersion = videoPlayer.getVersion();
        draft.debugInfo.totalTime = Math.round(playerDuration);
      }),
    );
  };

  streamInitialized = (playerDuration: number) => {
    const { contentType, currentItem, isPlaying, realStart, resumeState } = this.state;
    const { mediametrie } = this;

    let duration = playerDuration;

    if (contentType !== ContentType.Live) {
      // Static content: catchup, in-progress recording, VOD
      if (playerDuration === Infinity && currentItem) {
        const {
          selectedLocation: { duration: durationWithMargins },
        } = currentItem;
        duration = getIso8601DurationInSeconds(durationWithMargins);
      }
      this.setState({ duration }, this.checkResumePosition);

      if (mediametrie) {
        mediametrie.setDuration(duration);
      }
    }

    if (!isPlaying) {
      this.showOverlay();
    }

    this.initializeDebugInfo(duration);

    if (contentType === ContentType.Live) {
      // Live
      fireAndForget(this.goBackToLive());
    } else if (resumeState !== ResumeState.NoResumePosition && resumeState !== ResumeState.Seeking && resumeState !== ResumeState.Done && realStart > 0) {
      // Catchup, finished or in-progress recording, VOD
      this.seek(realStart);
    }
  };

  promptUserToResume = (): void => {
    const {
      localShowConfirmation,
      settings: { [Setting.AutoResume]: isAutoResumeEnabled },
    } = this.props;
    const { forcedResumePosition, realStart, resumePosition } = this.state;

    if (isAutoResumeEnabled || forcedResumePosition !== null) {
      // User setting override resume
      this.resumeConfirmationClosedCallback(ConfirmationModalResult.Button2, resumePosition, realStart);
      this.setState({ forcedResumePosition: null });
      return;
    }

    const duration = Math.abs(Math.floor(resumePosition - realStart));

    if (duration > 0) {
      const data = {
        button1Title: Localizer.localize('player.resume.start_over'),
        button2Title: Localizer.localize('player.resume.resume', { position: formatDuration(duration) }),
        question: Localizer.localize('player.resume.question'),
        title: Localizer.localize('player.resume.title'),
      };

      Messenger.once(MessengerEvents.MODAL_CONFIRMATION_CLOSED, (result: ConfirmationModalResult) => this.resumeConfirmationClosedCallback(result, resumePosition, realStart));
      localShowConfirmation(data);
    }
  };

  resumeConfirmationClosedCallback = (result: ConfirmationModalResult, resumePosition: number, startPosition: number): void => {
    if (result !== ConfirmationModalResult.Button1 && result !== ConfirmationModalResult.Button2) {
      // CANCEL: do nothing and simply close popup
      this.setState({ resumeState: ResumeState.NoResumePosition });
      return;
    }

    /*
     * BUTTON1: Start from beginning
     * BUTTON2: Resume from last position
     */
    const nextPosition = result === ConfirmationModalResult.Button1 ? startPosition : resumePosition;

    this.setState({
      resumePosition: nextPosition,
      resumeState: ResumeState.Seeking,
    });
  };

  getTrackFromLang = (mediaType: VideoPlayerMediaType, language: string): ?VideoPlayerMediaInfo => {
    const { audioMediaInfo, subtitlesMediaInfo } = this.state;

    if (mediaType === VideoPlayerMediaType.Audio) {
      return audioMediaInfo.find((track) => track.lang === language);
    }

    return getSubtitlesTrackFromCode(subtitlesMediaInfo, language);
  };

  autoSelectTracks = () => {
    const {
      settings: { [Setting.AutoSelectSubtitlesTrack]: autoSelectSubtitlesTrack, [Setting.LastSubtitlesTrack]: lastSubtitlesTrack },
    } = this.props;
    const { selectedSubtitlesMediaInfo } = this.state;
    const { isSubtitlesAutoSelectionApplied } = this;

    // Auto select subtitles track if required and available
    if (autoSelectSubtitlesTrack && !isSubtitlesAutoSelectionApplied) {
      const subtitlesTrack = this.getTrackFromLang(VideoPlayerMediaType.Subtitles, lastSubtitlesTrack);

      if (!subtitlesTrack) {
        return;
      }

      this.isSubtitlesAutoSelectionApplied = true;

      if ((subtitlesTrack?.index ?? -1) !== selectedSubtitlesMediaInfo) {
        this.setSubtitlesTrack(subtitlesTrack, false);
      }
    }
  };

  streamInfoUpdated = () => {
    const { videoPlayer } = this;

    if (!videoPlayer) {
      return;
    }

    const audioTracks = videoPlayer.getTracksFor(VideoPlayerMediaType.Audio);
    const currentAudioTrack = videoPlayer.getCurrentTrackFor(VideoPlayerMediaType.Audio);

    const currentSubtitlesTrack = videoPlayer.getCurrentTrackFor(VideoPlayerMediaType.Subtitles);
    const subtitlesTracks = videoPlayer.getTracksFor(VideoPlayerMediaType.Subtitles);

    this.setState(
      produce((draft) => {
        draft.audioMediaInfo = audioTracks;
        draft.selectedAudioMediaInfo = currentAudioTrack?.index ?? -1;
        draft.selectedSubtitlesMediaInfo = currentSubtitlesTrack?.index ?? -1;
        draft.subtitlesMediaInfo = subtitlesTracks;
      }),
      this.autoSelectTracks,
    );
  };

  handleBackOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement>): Promise<void> => {
    event.preventDefault();
    event.stopPropagation();

    return this.handleClose({ errorCode: NO_ERROR_CODE });
  };

  // Parameter 'seekTime' is in seconds
  handleStandardProgressBarSeekChanged = (seekTime: number) => {
    this.seek(seekTime);
  };

  // Parameter 'seekTime' is the difference between wanted position and current position (in seconds)
  handleLiveProgressBarSeekChanged = (seekTime: number) => {
    const { videoPlayer } = this;

    if (videoPlayer) {
      videoPlayer.liveSeek(seekTime);
    }
  };

  // Parameter 'seekTime' is in seconds
  seek = (seekTime: number, forcePlay?: boolean) => {
    const { userViewStartOffset } = this.state;
    const { videoPlayer } = this;

    if (videoPlayer && seekTime >= userViewStartOffset) {
      // DEBUG: check userViewStartOffset and startMargin to ensure user doesn't go before what's allowed

      videoPlayer.seek(seekTime);

      if (forcePlay) {
        fireAndForget(this.playVideo(false));
      }
    }
  };

  checkCurrentPlayedItem = () => {
    const { channel, playheadPosition, programInfoLoadStatus } = this.state;

    if (playheadPosition <= AccurateTimestamp.startOfDayInSeconds() || playheadPosition > AccurateTimestamp.nowInSeconds()) {
      return;
    }

    const newTime = Math.floor(playheadPosition);

    if (Math.abs(this.currentFeedItemTime - newTime) >= PLAYHEAD_POSITION_CHANGE_THRESHOLD) {
      this.currentFeedItemTime = newTime;
      if (programInfoLoadStatus !== ProgramInfoLoadStatus.NotStarted && channel) {
        this.findPlayedItemInEpgFeed(channel, playheadPosition);
      }
    }
  };

  // Parameter 'initData' is set only at player startup
  startVideoWithPromise = (initData: VideoPlayerInitData | null): Promise<VideoPlayerInitData | null> => {
    // Videofutur: at initial play and at every play following a pause
    const { isVideofuturAsset } = this.state;

    return isVideofuturAsset ? this.startVFVideoWithPromise(initData) : this.startTVVideoWithPromise(initData);
  };

  createVFStream = (initData: VideoPlayerInitData | null, vtiId: number): Promise<number> => {
    const { localSendVideofuturStreamCreateRequest } = this.props;
    const {
      abortController: { signal },
      currentVideofuturStreamId,
      distributorId,
    } = this;

    if (currentVideofuturStreamId !== null) {
      return Promise.resolve(currentVideofuturStreamId);
    }

    const isLicenceNeeded = Boolean(initData?.drm);

    // If no streamId, call createStream()
    return localSendVideofuturStreamCreateRequest(distributorId, vtiId, isLicenceNeeded, signal).then((response: BO_API_CREATE_STREAM_TYPE) => response.stream.id);
  };

  // Videofutur: at initial play and at every play following a pause
  startVFVideoWithPromise = async (initData: VideoPlayerInitData | null): Promise<VideoPlayerInitData | null> => {
    const { localSendVideofuturStreamStartRequest } = this.props;
    const { isBOStreamRequestPending, vtiId } = this.state;
    const {
      abortController: { signal },
      distributorId,
    } = this;

    if (isBOStreamRequestPending) {
      return Promise.reject(new PlayerError(VIDEOPLAYER_ERRORS.VS004StreamRequestPending));
    }

    if (!vtiId) {
      SentryWrapper.error({
        breadcrumbs: ['Function startVFVideoWithPromise', 'Before stream creation'],
        context: getInitDataAsSentryContext(initData),
        message: 'Missing vtiId',
        tagName: SentryTagName.Component,
        tagValue: SentryTagValue.Player,
      });
      return Promise.reject(new PlayerError(VIDEOPLAYER_ERRORS.VS998InternalError));
    }

    this.setState({ isBOStreamRequestPending: true });

    try {
      const id = await this.createVFStream(initData, vtiId);

      this.currentVideofuturStreamId = id > 0 ? id : null;

      if (!id) {
        this.setState({ isBOStreamRequestPending: false });
        SentryWrapper.error({
          breadcrumbs: ['Function startVFVideoWithPromise', 'Stream creation fulfilled'],
          context: getInitDataAsSentryContext(initData),
          message: 'Missing stream Id',
          tagName: SentryTagName.Component,
          tagValue: SentryTagValue.Player,
        });
        throw new PlayerError(VIDEOPLAYER_ERRORS.VS998InternalError);
      }

      await localSendVideofuturStreamStartRequest(distributorId, id, signal);

      this.setState({ isBOStreamRequestPending: false });

      // Return original initData
      return initData;
    } catch (error) {
      // BO_API_ERROR_TYPE or CustomNetworkError
      await ignoreIfAborted(signal, error, () => {
        this.startVFVideoWithPromiseErrorHandler(error, initData);
      });

      return null;
    }
  };

  startVFVideoWithPromiseErrorHandler = async (error: BO_API_ERROR_TYPE | CustomNetworkError, initData: VideoPlayerInitData | null): Promise<void> => {
    if (isInvalidTokenError(error)) {
      // Nothing to do: app will restart
      return;
    }

    if (error instanceof CustomNetworkError) {
      const {
        networkError: { result },
      } = error;
      const code = error.getCustomCode();

      // BO error code when max number of concurrent streams being played is reached
      if (code === BO_STREAM_MAX_CONCURRENT_STREAMS_REACHED && result) {
        const maxNbStreamsStr = result.variables?.maxNbStreams;
        const maxNbStreams = typeof maxNbStreamsStr === 'number' ? Number(maxNbStreamsStr) : 1;
        const errorMsg = Localizer.localize('player.errors.max_concurrent_streams_reached', { count: maxNbStreams });
        await this.handleClose({ errorCode: code.toString(), errorMsg, isLargeNotificationNeeded: true });
      }
    }

    // Other errors
    this.setState({ isBOStreamRequestPending: false });
    const { errorMsg, status } = buildBOErrorResponse(null, error);

    SentryWrapper.error({
      breadcrumbs: ['Function startVFVideoWithPromise', 'Stream creation rejected'],
      context: getInitDataAsSentryContext(initData),
      error: error instanceof Error ? error : undefined,
      message: errorMsg,
      tagName: SentryTagName.Component,
      tagValue: SentryTagValue.Player,
    });

    this.displayErrorMessage(status, errorMsg);
    throw error;
  };

  initializeKeepAliveIfRequired = (urlLifecycle?: NETGEM_API_URL_LIFECYCLE, url: string): Promise<string | null> => {
    if (!urlLifecycle || urlLifecycle.type === undefined || urlLifecycle.heartbeat === undefined) {
      return Promise.resolve(null);
    }

    const { heartbeat, type } = urlLifecycle;
    this.keepAlive = new KeepAlive(type, heartbeat);
    return this.keepAlive.initialize(url);
  };

  startTVVideoWithPromiseWithoutEntitlement = async (initData: VideoPlayerInitData): Promise<VideoPlayerInitData> => {
    const { channels } = this.props;
    const { channelId, url: originalUrl } = initData;

    const urlLifecycleDms: NETGEM_API_URL_LIFECYCLE | null = getChannelUrlLifecycle(channels, channelId);
    if (!urlLifecycleDms) {
      this.setDebugInfoUrls(originalUrl);
      return Promise.resolve(initData);
    }

    // Check if keep-alive is enabled
    const redirectUrl: string | null = await this.initializeKeepAliveIfRequired(urlLifecycleDms, originalUrl);

    if (!redirectUrl) {
      this.setDebugInfoUrls(originalUrl);
      return Promise.resolve(initData);
    }

    this.setDebugInfoUrls(redirectUrl);
    return Promise.resolve({
      ...initData,
      url: redirectUrl,
    });
  };

  startTVVideoWithPromiseWithEntitlement = async (initData: VideoPlayerInitData, entitlement: NETGEM_API_V8_METADATA_SCHEDULE_VIDEO_STREAM_ENTITLEMENT_PARAM): Promise<VideoPlayerInitData> => {
    const { localSendNtgEntitlementGetRequest } = this.props;
    const {
      abortController: { signal },
    } = this;

    const { channelId, drm, manifestUpdatePeriod, trackingStart, type } = initData;
    const { customData: entCustomData, service, url: entUrl } = entitlement;

    // Call entitlement to get streams params
    try {
      const requestResponse: NETGEM_API_ENTITLEMENT_RESULT = await localSendNtgEntitlementGetRequest(entCustomData, service, entUrl, channelId || '', signal);

      const { action, customHeaders, fairplayCertificateUrl, fairplayContentKeySystem, laUrl, streamUrl: url, urlLifecycle, vuDrmToken } = requestResponse;
      if (!url) {
        const errorCode = action === 'subscribe' ? VIDEOPLAYER_ERRORS.VP004NtgEntitlementNoSubscriptionError : VIDEOPLAYER_ERRORS.VP002NtgEntitlementRequestError;
        SentryWrapper.error({
          breadcrumbs: ['Function startTVVideoWithPromise', 'Entitlement get fulfilled'],
          context: {
            initData: JSON.stringify(initData),
            requestResponse: JSON.stringify(requestResponse),
          },
          message: `No URL returned${action ? ` (${action})` : ''}`,
          tagName: SentryTagName.Component,
          tagValue: SentryTagValue.Player,
        });
        return Promise.reject(new PlayerError(errorCode, Localizer.localize('player.unsubscribed_channel')));
      }

      // Save entitlement for later release
      this.currentEntitlementParams = {
        channelId,
        customData: entCustomData,
        service,
        url: entUrl,
      };

      // Check if keep-alive is enabled
      const redirectUrl: string | null = await this.initializeKeepAliveIfRequired(urlLifecycle, url);

      this.setDebugInfoUrls(url, laUrl);

      // Return definitive initData to pass to player
      return {
        channelId,
        customHeaders,
        drm,
        fairplayCertificateUrl,
        fairplayContentKeySystem,
        laUrl,
        manifestUpdatePeriod,
        trackingStart,
        type,
        url: redirectUrl ?? url,
        vuDrmToken,
      };
    } catch (error) {
      if (signal.aborted) {
        // Player was closed while starting
        throw new PlayerError(VIDEOPLAYER_ERRORS.VS996StartupCancelled);
      }

      SentryWrapper.error({
        breadcrumbs: ['Function startTVVideoWithPromise', 'Entitlement get rejected'],
        context: getInitDataAsSentryContext(initData),
        error,
        tagName: SentryTagName.Component,
        tagValue: SentryTagValue.Player,
      });

      // Entitlement error
      logError(error);
      let errorCode: ?string = VIDEOPLAYER_ERRORS.VP002NtgEntitlementRequestError;
      let message: ?string = undefined;

      if (error instanceof CustomNetworkError) {
        const {
          networkError: { result },
        } = error;

        if (result) {
          ({ ntgCode: errorCode } = result);

          if (errorCode === NetgemNetworkCode.MissingDmsChannel) {
            message = Localizer.localize('player.errors.missing_channel');
          } else if (errorCode === NetgemNetworkCode.ConcurrencyLimit) {
            message = Localizer.localize('player.errors.max_concurrency_exceeded');
          } else if (errorCode === NetgemNetworkCode.Geolock) {
            message = Localizer.localize('player.errors.content_unavailable_in_country');
          } else if (errorCode === NetgemNetworkCode.MissingRight) {
            message = Localizer.localize('player.errors.missing_right');
          }
        }
      } else if (error instanceof PlayerError) {
        ({ code: errorCode } = error);
      }

      throw new PlayerError(errorCode ?? VIDEOPLAYER_ERRORS.VP002NtgEntitlementRequestError, message ?? undefined);
    }
  };

  startTVVideoWithPromise = (initData: VideoPlayerInitData | null): Promise<VideoPlayerInitData | null> => {
    // Entitlement: at initial start only
    if (!initData) {
      return Promise.resolve(null);
    }

    const { ntgEntitlement: entitlement } = initData;
    if (entitlement) {
      return this.startTVVideoWithPromiseWithEntitlement(initData, entitlement);
    }

    return this.startTVVideoWithPromiseWithoutEntitlement(initData);
  };

  // Return playhead position in ISO8601 format
  getBookmark = (): string => {
    const { playheadPosition } = this.state;

    return getRoundedDurationToISOString(playheadPosition * MILLISECONDS_PER_SECOND, 1);
  };

  sendVideofuturStreamStop = async (streamId: number, stopType: StopStreamKind): Promise<void> => {
    const { localSendVideofuturStreamStopRequest } = this.props;
    const { distributorId } = this;

    this.currentVideofuturStreamId = null;

    this.setState({ isBOStreamRequestPending: true });
    try {
      await localSendVideofuturStreamStopRequest(distributorId, streamId, this.getBookmark());
    } catch (error) {
      // CustomNetworkError

      if (isInvalidTokenError(error)) {
        // Nothing to do: app will restart
        throw error;
      }

      SentryWrapper.error({
        breadcrumbs: ['Function sendStreamStop', 'Stream stop rejected'],
        context: {
          distributorId,
          stopType,
          streamId,
        },
        message: error.message,
        tagName: SentryTagName.Component,
        tagValue: SentryTagValue.Player,
      });

      throw new PlayerError(VIDEOPLAYER_ERRORS.VS005StreamStopError);
    } finally {
      this.setState({ isBOStreamRequestPending: false });
    }
  };

  sendStreamStop = async (stopType: StopStreamKind): Promise<void> => {
    const { localSendNtgEntitlementReleaseRequest, playerItem } = this.props;
    const { isBOStreamRequestPending } = this.state;
    const { distributorId } = this;

    if (!playerItem || playerItem.type === ExtendedItemType.OfflineContent) {
      // Offline content: nothing to stop
      return Promise.resolve();
    }

    if (stopType === StopStreamKind.Initialization) {
      // Stop occurring at initialization
      return Promise.resolve();
    }

    // Final stop or pause
    this.updateViewingHistory();

    if (isBOStreamRequestPending) {
      throw new PlayerError(VIDEOPLAYER_ERRORS.VS004StreamRequestPending);
    }

    // Videofutur: at every pause and at final stop
    const { currentVideofuturStreamId: streamId } = this;
    if (streamId) {
      return this.sendVideofuturStreamStop(streamId, stopType);
    }

    if (stopType === StopStreamKind.Pause || this.currentEntitlementParams === null) {
      // Pause
      return Promise.resolve();
    }

    // Final stop: release entitlement
    const { customData, service, url, channelId } = this.currentEntitlementParams;
    this.currentEntitlementParams = null;

    try {
      return await localSendNtgEntitlementReleaseRequest(customData, service, url, channelId || '');
    } catch (error) {
      SentryWrapper.error({
        breadcrumbs: ['Function sendStreamStop', 'Entitlement release rejected'],
        context: {
          currentEntitlementParams: JSON.stringify(this.currentEntitlementParams),
          distributorId,
          stopType,
        },
        error,
        tagName: SentryTagName.Component,
        tagValue: SentryTagValue.Player,
      });

      const { message, status } = error;
      if (status === HttpStatus.NotFound) {
        // Entitlement release failed but that's not a problem: let's ignore it
        return Promise.resolve();
      }

      this.displayErrorMessage(status, message);
      throw new PlayerError(VIDEOPLAYER_ERRORS.VP005NtgEntitlementReleaseError);
    }
  };

  skip = (direction: SkipDirection, event?: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement> | SyntheticKeyboardEvent<HTMLElement>) => {
    const { contentType, duration, isControllerEnabled, isTimeshiftEnabled, playheadPosition } = this.state;
    const { videoController, videoPlayer } = this;

    if (!videoPlayer || !videoController || !isControllerEnabled || (contentType === ContentType.Live && !isTimeshiftEnabled)) {
      // Player or controller not initialized, play not started yet or timeshift disabled
      return;
    }

    const { type: skipping, value: skipValue } = getSkippingTypeAndValue(direction, event);

    this.brieflyShow(skipping);

    if (contentType === ContentType.Live) {
      // Timeshifting
      videoPlayer.liveSeek(skipValue);
    } else {
      // Regular seeking
      this.seek(getBoundedValue(playheadPosition + skipValue, 0, duration));
    }
  };

  handleSkipBackwardOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement> | SyntheticKeyboardEvent<HTMLElement>) => {
    this.skip(SkipDirection.Backward, event);
  };

  handleSkipForwardOnClick = (event: SyntheticMouseEvent<HTMLElement> | SyntheticTouchEvent<HTMLElement> | SyntheticKeyboardEvent<HTMLElement>) => {
    this.skip(SkipDirection.Forward, event);
  };

  brieflyShow = (skipping: SkippingKind) => {
    this.setState({ skipping });
    setTimeout(() => this.setState({ skipping: SkippingKind.None }), BLINKING_PICTO_DURATION);
  };

  changeVolume = (step: number) => {
    const { isControllerEnabled, isCurrentItemBlackedOut, volume } = this.state;

    if (!isControllerEnabled || isCurrentItemBlackedOut) {
      // Play not started yet or black out is enabled
      return;
    }

    let newVolume = volume + step;

    if (newVolume < 0) {
      newVolume = 0;
    } else if (newVolume > 1) {
      newVolume = 1;
    }

    this.handleVolumeChanged(newVolume);
  };

  handleVolumeChanged = (volume: number) => {
    const { videoPlayer } = this;
    const { isMuted } = this.state;

    if (!videoPlayer) {
      return;
    }

    if (isMuted) {
      this.setMute(false);
    } else {
      videoPlayer.setVolume(volume);
      this.setVolume(volume);
    }
  };

  handleVolumeOnClick = () => {
    const { isControllerEnabled, isCurrentItemBlackedOut, isMuted } = this.state;

    if (!isControllerEnabled || isCurrentItemBlackedOut) {
      // Play not started yet or black out is enabled
      return;
    }

    this.setMute(!isMuted);
  };

  setAudioTrack = (track: ?VideoPlayerMediaInfo, saveSetting: boolean) => {
    const { localUpdateSetting } = this.props;
    const { videoPlayer } = this;

    if (videoPlayer && track) {
      videoPlayer.setCurrentTrack(track);

      if (saveSetting) {
        // Save audio track in settings for future use
        localUpdateSetting(Setting.LastAudioTrack, getAudioSettingCode(track));
      }
    }
  };

  setSubtitlesTrack = (track: VideoPlayerMediaInfo | null, saveSetting: boolean) => {
    const { localUpdateSetting } = this.props;
    const { videoPlayer } = this;

    if (!videoPlayer) {
      return;
    }

    if (saveSetting) {
      // Save subtitles track in settings for future use (empty string means "no subtitles")
      localUpdateSetting(Setting.LastSubtitlesTrack, getSubtitlesSettingCode(track));
    }

    if (track) {
      videoPlayer.setCurrentTrack(track);
    } else {
      videoPlayer.unsetCurrentTrack(VideoPlayerMediaType.Subtitles);
    }
  };

  handleNewAudioTrackSelected = (index: number) => {
    const { audioMediaInfo } = this.state;

    this.setAudioTrack(
      audioMediaInfo.find((elt) => elt.index === index),
      true,
    );
  };

  handleNewSubtitlesTrackSelected = (index: number) => {
    const { subtitlesMediaInfo } = this.state;

    this.setSubtitlesTrack(subtitlesMediaInfo.find((elt) => elt.index === index) ?? null, true);
  };

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleNewVideoQualitySelected = (index: number) => {
    const { videoPlayer } = this;

    if (!videoPlayer) {
      return;
    }

    if (index === -1) {
      videoPlayer.setAutoSwitchQualityFor(VideoPlayerMediaType.Video, true);
    } else {
      videoPlayer.setAutoSwitchQualityFor(VideoPlayerMediaType.Video, false);
      videoPlayer.setQualityFor(VideoPlayerMediaType.Video, index);
    }
  };

  setVolume = (volume: number) => {
    const { localUpdateSetting } = this.props;

    this.setState({ volume });

    // Store volume setting
    localUpdateSetting(Setting.Volume, volume);
  };

  setMute = (mute: boolean) => {
    const { videoPlayer } = this;

    if (!videoPlayer) {
      return;
    }

    const isMuted = videoPlayer.isMuted();

    // New mute value is only applied if it makes sense
    if (mute && !isMuted) {
      videoPlayer.setMute(true);
      this.setState({ isMuted: true });
    } else if (!mute && isMuted) {
      videoPlayer.setMute(false);
      this.setState({ isMuted: false });
    }
  };

  goBackToLive = (): Promise<void> => {
    const { mediametrie, videoPlayer } = this;

    if (!videoPlayer) {
      return this.handleClose({ errorCode: VIDEOPLAYER_ERRORS.VS998InternalError });
    }

    if (mediametrie) {
      mediametrie.notifyStop();
    }

    videoPlayer.goBackToLive();
    return this.playVideo(false);
  };

  handleGoToLiveOnClick: () => Promise<void> = this.goBackToLive;

  // Go back to live (live only when timeshifting)
  handleGoBackToLiveHotKey = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    const { contentType, timeshift } = this.state;

    event.preventDefault();
    event.stopPropagation();

    if (contentType === ContentType.Live && timeshift > 0) {
      fireAndForget(this.goBackToLive());
    }
  };

  toggleFullscreen = () => {
    const { isInFullscreen } = this.state;

    if (isInFullscreen) {
      this.handleExitFullScreenOnClick();
    } else {
      this.handleEnterFullScreenOnClick();
    }
  };

  handleEnterFullScreenOnClick = () => {
    const { fullscreenEnabled } = fscreen;

    if (fullscreenEnabled) {
      fscreen.requestFullscreen(document.body);
    }

    this.showOverlay();
  };

  handleExitFullScreenOnClick = () => {
    const { fullscreenElement, fullscreenEnabled } = fscreen;

    if (fullscreenElement !== null && fullscreenEnabled) {
      fscreen.exitFullscreen();
    }

    this.showOverlay();
  };

  handleOnMouseMoveOnVideo = () => {
    this.showOverlay();
  };

  handleOnClickOnVideo = (event: SyntheticMouseEvent<HTMLElement>) => {
    if (this.clickTimer) {
      // Double click
      this.resetClickTimer();
      this.executeDoubleClick(event);
      return;
    }

    this.clickTimer = setTimeout(() => {
      // Single click
      this.resetClickTimer();

      fireAndForget(this.togglePlayVideo());
    }, DOUBLE_CLICK.MaxDuration);
  };

  togglePlayVideo = (): Promise<void> => {
    const { contentType, isTimeshiftEnabled } = this.state;
    const { videoPlayer } = this;

    if (!videoPlayer || (contentType === ContentType.Live && !isTimeshiftEnabled)) {
      // No player or timeshift disabled
      return Promise.resolve();
    }

    if (videoPlayer.isPaused()) {
      return this.playVideo();
    }

    return this.pauseVideo();
  };

  executeDoubleClick = (event: SyntheticMouseEvent<HTMLElement>) => {
    const { videoContainer } = this;

    if (!videoContainer) {
      return;
    }

    const { offsetWidth: videoWidth } = videoContainer;
    const { clientX: mouseX } = event;
    const { fullscreenElement, fullscreenEnabled } = fscreen;

    if (mouseX <= videoWidth * DOUBLE_CLICK.BackwardSkipAreaThreshold) {
      // Skip backward
      this.handleSkipBackwardOnClick(event);
    } else if (mouseX >= videoWidth * DOUBLE_CLICK.ForwardSkipAreaThreshold) {
      // Skip forward
      this.handleSkipForwardOnClick(event);
    } else if (fullscreenElement !== null && fullscreenEnabled) {
      // Exit fullscreen
      this.handleExitFullScreenOnClick();
    } else {
      // Enter fullscreen
      this.handleEnterFullScreenOnClick();
    }
  };

  handleOnMouseEnterController = () => {
    this.isOverlayHovered = true;
    this.showOverlay(false);
  };

  handleOnMouseLeaveController = () => {
    this.isOverlayHovered = false;
    this.hideOverlay();
  };

  showOverlay = (thenHide: boolean = true) => {
    const { isOverlayHovered } = this;

    this.resetOverlayTimer();
    this.setState({ isOverlayVisible: true }, thenHide && !isOverlayHovered ? this.hideOverlay : undefined);
  };

  hideOverlay = () => {
    this.resetOverlayTimer();
    this.overlayTimer = setTimeout(() => this.setState({ isOverlayVisible: false }), OVERLAY_TIMEOUT);
  };

  hideControls = () => {
    this.resetOverlayTimer();
    this.setState({ isOverlayVisible: false });
  };

  pauseVideo = async (): Promise<void> => {
    const { isControllerEnabled } = this.state;
    const { videoPlayer } = this;

    if (!isControllerEnabled) {
      // Play not started yet
      return;
    }

    this.sendCurrentPlayerState(DataCollectionPlayerState.Pause);
    this.hidePlayPicto = false;

    if (videoPlayer && !videoPlayer.isPaused()) {
      videoPlayer.pause();

      try {
        await this.sendStreamStop(StopStreamKind.Pause);
      } catch (error) {
        if (isInvalidTokenError(error)) {
          // Nothing to do: app will restart
          return;
        }
        if (error instanceof PlayerError) {
          const { code } = error;
          if (typeof code === 'string' && code !== VIDEOPLAYER_ERRORS.VS004StreamRequestPending) {
            this.displayErrorMessage(code);
          }
        }
      }
    }
  };

  restartSession = async (): Promise<void> => {
    const { playerItem } = this.props;
    const { playheadPosition } = this.state;

    if (!playerItem?.programMetadata) {
      return this.handleClose({ errorCode: VIDEOPLAYER_ERRORS.VS995SessionTimeOut });
    }

    const { item, locationId, programMetadata, seriesMetadata, viewingHistoryId, vtiId } = playerItem;

    this.isRestartingSession = true;

    this.stopMediametrie();
    this.stopDataCollection();

    try {
      await this.sendStreamStop(StopStreamKind.FinalStop);
      return await this.openVodItem(item, programMetadata, seriesMetadata ?? null, vtiId ?? null, viewingHistoryId ?? null, locationId ?? null, playheadPosition);
    } catch (error) {
      if (isInvalidTokenError(error)) {
        // Nothing to do: app will restart
        return Promise.resolve();
      }
      if (error instanceof PlayerError) {
        const { code } = error;
        if (typeof code === 'string' && code !== VIDEOPLAYER_ERRORS.VS004StreamRequestPending) {
          this.displayErrorMessage(code);
          return Promise.resolve();
        }
      }
      throw error;
    }
  };

  playVideo = async (notifyDataCollector: boolean = true): Promise<void> => {
    const { isControllerEnabled } = this.state;
    const { videoPlayer } = this;

    if (!isControllerEnabled || !videoPlayer) {
      // Play not started yet
      return Promise.resolve();
    }

    if (notifyDataCollector) {
      this.sendCurrentPlayerState(DataCollectionPlayerState.Play);
    }

    if (videoPlayer.isPaused() === false) {
      return Promise.resolve();
    }

    if (this.keepAlive?.getState() === KeepAliveState.ForbiddenError) {
      // Session has expired: start a new one
      return this.restartSession();
    }

    // Resume play as usual
    try {
      await this.startVideoWithPromise(null);
      return await videoPlayer.play();
    } catch (error) {
      if (isInvalidTokenError(error)) {
        // Nothing to do: app will restart
        return Promise.resolve();
      }
      if (error instanceof PlayerError) {
        const { code } = error;
        if (typeof code === 'string' && code !== VIDEOPLAYER_ERRORS.VS004StreamRequestPending) {
          this.displayErrorMessage(code);
        }
      }

      throw error;
    }
  };

  handlePauseOnClick: () => Promise<void> = this.pauseVideo;

  handlePlayOnClick: (notifyDataCollector?: boolean) => Promise<void> = this.playVideo;

  getTileType = (): TileConfigTileType => {
    const { playerItem } = this.props;

    if (!playerItem) {
      // Default type
      return TileConfigTileType.Gemtv;
    }

    const { tileType, type } = playerItem;
    return tileType ?? (type === ExtendedItemType.VOD ? TileConfigTileType.Portrait : TileConfigTileType.Gemtv);
  };

  handleDownloadOnClick = async (): Promise<void> => {
    const {
      channel,
      debugInfo: { streamUrl },
      downloadOperationId: inProgressDownloadOperationId,
      programMetadata,
      seriesMetadata,
      title,
    } = this.state;
    const { videoPlayer } = this;

    if (!videoPlayer || inProgressDownloadOperationId !== null) {
      return;
    }

    if (await ShakaStorage.exists(streamUrl)) {
      Messenger.emit(MessengerEvents.NOTIFY_INFO, <div>&#xAB;&nbsp;{title}&nbsp;&#xBB; a déjà été téléchargé</div>);
      return;
    }

    // Id of the download operation (used for the toast and ShakaStorage operation)
    const downloadOperationId: string = crypto.randomUUID();

    const assetId = seriesMetadata?.id ?? programMetadata?.id;
    const channelId = channel?.epgid;

    this.setState({ downloadOperationId }, async () => {
      await videoPlayer.download(downloadOperationId, { assetId, channelId, title: title ?? '' });
      this.setState({ downloadOperationId: null });
    });
  };

  handleInfoOnClick = () => {
    const { contentType, currentItem, programMetadata, seriesMetadata, timeshift } = this.state;

    if (currentItem && programMetadata) {
      Messenger.emit(MessengerEvents.OPEN_CARD, {
        displayedSectionCount: 0,
        isOpenFromPlayer: true,
        isTimeshifting: contentType === ContentType.Live && timeshift > TIMESHIFT_THRESHOLD,
        item: currentItem,
        programMetadata,
        seriesMetadata,
        tileType: this.getTileType(),
      });
    }
  };

  updateMaxBitrate = (isBitrateLimited: boolean) => {
    const { maxBitrate } = this.props;
    const { videoPlayer } = this;

    videoPlayer?.updateMaxBitrate(isBitrateLimited ? maxBitrate : -1);
  };

  handleChannelZapperChannelChanged = (channel: NETGEM_API_CHANNEL): Promise<void> => {
    const { timeshift } = this.state;
    const { isZapping, videoPlayer } = this;

    if (isZapping) {
      // If a channel us currently initializing, we skip all following changes and keep the last one to execute it at the end
      this.nextChannel = channel;
      return Promise.resolve();
    }

    if (timeshift > TIMESHIFT_THRESHOLD) {
      videoPlayer?.goBackToLive();
    }

    this.isZapping = true;

    // Reset auto-selection of subtitles track
    this.isSubtitlesAutoSelectionApplied = false;

    this.setState(
      produce((draft) => {
        draft.channel = channel;
        draft.channelImageUrl = '';
        draft.currentItem = null;
      }),
      () => {
        this.stopProgramDataCollection(false);
        fireAndForget(this.loadChannelImage(channel.epgid));
        return this.initializeLivePlayer(ProgramInfoLoadStatus.NotStarted);
      },
    );

    return Promise.resolve();
  };

  finishZapping = () => {
    const { isZapping, nextChannel } = this;

    if (!isZapping) {
      return;
    }

    this.isZapping = false;

    if (nextChannel) {
      fireAndForget(this.handleChannelZapperChannelChanged(nextChannel));
      this.nextChannel = null;
    }
  };

  // Find which program is being played ('playheadPosition', if provided, is in seconds)
  findPlayedItemInEpgFeed = (channel: NETGEM_API_CHANNEL, playheadPosition?: number) => {
    const { currentItem, isTimeshiftSwitching } = this.state;
    const { isZapping } = this;
    const { epgid: channelId } = channel;

    const time = typeof playheadPosition === 'number' && playheadPosition > 0 ? playheadPosition : AccurateTimestamp.nowInSeconds();

    // When a live program starts, there's a brief period during which playheadPosition is 0
    const matchingItem = EpgManager.findItem(channelId, time);

    if (!matchingItem) {
      // No matching item in EPG
      this.setState({
        currentItem: null,
        duration: 0,
        programInfoLoadStatus: ProgramInfoLoadStatus.ItemNotFound,
        programMetadata: null,
        seriesEpisodeText: null,
        seriesMetadata: null,
        title: '',
      });

      return;
    }

    if (currentItem) {
      const { selectedProgramId: currentProgramId } = currentItem;
      const { selectedProgramId: matchingProgramId } = matchingItem;

      if (currentProgramId === matchingProgramId) {
        // Same program: no need to retrieve metadata again
        if (isTimeshiftSwitching) {
          this.restartProgramDataCollection();
          this.setTimeshiftSwitch(false);
        }
        return;
      }
    }

    this.setTimeshiftSwitch(false);

    // Reset auto-selection of subtitles track
    this.isSubtitlesAutoSelectionApplied = false;

    if (!isZapping) {
      // Program ended or user used timeshift to watch another program from the same timeshift buffer
      this.stopProgramDataCollection(true);
    }

    this.setState(
      produce((draft) => {
        draft.currentItem = matchingItem;
      }),
      this.updateEpgInfo,
    );
  };

  loadProgramMetadata = async (currentItem: NETGEM_API_V8_FEED_ITEM): Promise<void> => {
    const { localSendV8MetadataRequest } = this.props;
    const {
      abortController: { signal },
    } = this;

    const { selectedProgramId, seriesId } = currentItem;

    try {
      const metadata: NETGEM_API_V8_METADATA = await localSendV8MetadataRequest(selectedProgramId, METADATA_KIND_PROGRAM, signal);

      const programMetadata = ((metadata: any): NETGEM_API_V8_METADATA_PROGRAM);
      const { duration } = programMetadata;
      const title = getTitle(programMetadata, Localizer.language);

      // Program duration: used to initialize the duration (that will be adjusted by the player later)
      this.setState(
        produce((draft) => {
          draft.duration = getIso8601DurationInSeconds(duration);
          draft.programMetadata = programMetadata;
          draft.seriesEpisodeText = formatSeasonEpisodeNbr(programMetadata);
          draft.title = title;
        }),
      );

      this.checkLiveRecordingStatus();

      if (seriesId) {
        // Series metadata
        this.setState({ programInfoLoadStatus: ProgramInfoLoadStatus.LoadingSeriesMetadata }, () => this.loadSeriesMetadata(seriesId));
      } else {
        this.setState({
          programInfoLoadStatus: ProgramInfoLoadStatus.Loaded,
          seriesMetadata: null,
        });
      }

      // Update data collection message
      const {
        selectedLocation: { duration: liveDuration, id: locationId },
      } = currentItem;
      this.updateProgramDataCollection(programMetadata, locationId ?? null, liveDuration);
    } catch (error) {
      await ignoreIfAborted(signal, error, () =>
        this.setState({
          duration: 0,
          programInfoLoadStatus: ProgramInfoLoadStatus.ItemNotFound,
          programMetadata: null,
          seriesEpisodeText: null,
          title: '',
        }),
      );
    }
  };

  // Only happens in live (channel or program change)
  updateEpgInfo = () => {
    const { currentItem } = this.state;

    if (!currentItem) {
      // Not suppose to happen since the caller sets currentItem with a non-null value beforehand
      return;
    }

    // Program metadata
    this.setState({ programInfoLoadStatus: ProgramInfoLoadStatus.LoadingProgramMetadata }, () => this.loadProgramMetadata(currentItem));
  };

  loadSeriesMetadata = async (seriesId: string): Promise<void> => {
    const { localSendV8MetadataRequest } = this.props;
    const {
      abortController: { signal },
    } = this;

    try {
      const metadata: NETGEM_API_V8_METADATA = await localSendV8MetadataRequest(seriesId, METADATA_KIND_SERIES, signal);
      const seriesMetadata = ((metadata: any): NETGEM_API_V8_METADATA_SERIES);
      this.setState(
        produce((draft) => {
          draft.programInfoLoadStatus = ProgramInfoLoadStatus.Loaded;
          draft.seriesMetadata = seriesMetadata;
        }),
      );
    } catch (error) {
      await ignoreIfAborted(signal, error, () =>
        this.setState({
          programInfoLoadStatus: ProgramInfoLoadStatus.Loaded,
          seriesMetadata: null,
        }),
      );
    }
  };

  checkBlackOut = (location: NETGEM_API_V8_METADATA_SCHEDULE_LOCATION): void => {
    const { isCurrentItemBlackedOut: prevIsCurrentItemBlackedOut } = this.state;
    const { isLivePlaybackEnabled } = location;

    const isCurrentItemBlackedOut = isLivePlaybackEnabled === false;

    if (isCurrentItemBlackedOut && !prevIsCurrentItemBlackedOut) {
      // Entering black out
      this.setMute(true);
    } else if (!isCurrentItemBlackedOut && prevIsCurrentItemBlackedOut) {
      // Exiting black out
      this.setMute(false);
    }

    this.setState({ isCurrentItemBlackedOut });

    // All other cases are ignored since there is no change
  };

  renderBlackOutOverlay = (): React.Node => {
    const { applicationName } = this.props;
    const { isCurrentItemBlackedOut } = this.state;

    return isCurrentItemBlackedOut ? (
      <div className='blackOutWrapper'>
        <img alt={applicationName} src={AppMobileAppLogo} />
        <div className='blackOutTitle'>{Localizer.localize('player.blackout.title')}</div>
        <div>{Localizer.localize('player.blackout.text')}</div>
      </div>
    ) : null;
  };

  renderLoader = (): React.Node => {
    const { channelImageUrl, isBuffering, isVideofuturAsset } = this.state;
    const visibilityClass = isBuffering ? 'visible' : '';

    if (isVideofuturAsset) {
      // VOD
      return (
        <div className={clsx('loaderWrapper', visibilityClass)}>
          <InfiniteCircleLoaderArc />
        </div>
      );
    }

    // TV
    if (!channelImageUrl) {
      return null;
    }

    return (
      <div className={clsx('loaderWrapper', visibilityClass)}>
        <img alt='' draggable={false} src={channelImageUrl} />
      </div>
    );
  };

  getVideoQuality = (): Definition => {
    const { videoElement } = this;

    if (!videoElement) {
      return Definition.SD;
    }

    const { videoHeight, videoWidth } = videoElement;
    if (videoHeight >= RESOLUTION.FourK.Height && videoWidth >= RESOLUTION.FourK.Width) {
      // 4K
      return Definition.FourK;
    }

    if (videoHeight >= RESOLUTION.HD.Height && videoWidth >= RESOLUTION.HD.Width) {
      // HD Ready
      return Definition.HD;
    }

    // SD
    return Definition.SD;
  };

  renderVideoController = (): React.Node => {
    const { playerItem } = this.props;
    const {
      audioMediaInfo,
      bufferedTimeRanges,
      channel,
      channelImageUrl,
      contentType,
      controlLevel,
      currentItem,
      duration,
      endMargin,
      isControllerEnabled,
      isCurrentItemBlackedOut,
      isInFullscreen,
      isLiveRecording,
      isMuted,
      isOverlayVisible,
      isPlaying,
      isTimeshiftEnabled,
      isTrailer,
      liveBufferLength,
      location,
      playheadPosition,
      programInfoLoadStatus,
      programMetadata,
      realEnd,
      realStart,
      selectedAudioMediaInfo,
      selectedSubtitlesMediaInfo,
      seriesEpisodeText,
      seriesMetadata,
      startMargin,
      subtitlesMediaInfo,
      timeshift,
      title,
      totalDuration,
      userViewEndOffset,
      userViewStartOffset,
      volume,
    } = this.state;
    const { initialChannelId } = this;

    const { epgid: channelId, name: channelName } = channel || {};
    const isProgramInfoHidden = programInfoLoadStatus !== ProgramInfoLoadStatus.Loaded && programInfoLoadStatus !== ProgramInfoLoadStatus.ItemNotFound;
    const type = playerItem?.type ?? ExtendedItemType.OfflineContent;

    return (
      <VideoController
        audioMediaInfo={audioMediaInfo}
        bufferedTimeRanges={bufferedTimeRanges}
        channelId={channelId || initialChannelId}
        channelImageUrl={channelImageUrl}
        channelName={channelName || ''}
        contentType={contentType}
        controlLevel={controlLevel}
        currentItem={currentItem}
        duration={duration}
        endMargin={endMargin}
        isBlackOutEnabled={isCurrentItemBlackedOut}
        isControllerEnabled={isControllerEnabled}
        isInFullscreen={isInFullscreen}
        isLiveRecording={isLiveRecording}
        isMuted={isMuted}
        isPlaying={isPlaying}
        isProgramInfoHidden={isProgramInfoHidden}
        isTimeshiftEnabled={isTimeshiftEnabled}
        isTrailer={isTrailer}
        isVisible={isOverlayVisible}
        itemType={type}
        liveBufferLength={liveBufferLength}
        location={location}
        onChannelChanged={this.handleChannelZapperChannelChanged}
        onDownloadClick={this.handleDownloadOnClick}
        onEnterFullScreenClick={this.handleEnterFullScreenOnClick}
        onExitFullScreenClick={this.handleExitFullScreenOnClick}
        onGoToLiveClick={this.handleGoToLiveOnClick}
        onInfoClick={this.handleInfoOnClick}
        onLiveProgressBarSeekChanged={this.handleLiveProgressBarSeekChanged}
        onMouseEnter={this.handleOnMouseEnterController}
        onMouseLeave={this.handleOnMouseLeaveController}
        onNewAudioTrackSelected={this.handleNewAudioTrackSelected}
        onNewSubtitlesTrackSelected={this.handleNewSubtitlesTrackSelected}
        onPauseClick={this.handlePauseOnClick}
        onPlayClick={this.handlePlayOnClick}
        onSkipBackwardClick={this.handleSkipBackwardOnClick}
        onSkipForwardClick={this.handleSkipForwardOnClick}
        onStandardProgressBarSeekChanged={this.handleStandardProgressBarSeekChanged}
        onVolumeChanged={this.handleVolumeChanged}
        onVolumeClick={this.handleVolumeOnClick}
        playheadPosition={playheadPosition}
        programMetadata={programMetadata}
        realEnd={realEnd}
        realStart={realStart}
        ref={(instance) => {
          this.videoController = instance;
        }}
        selectedAudioMediaInfo={selectedAudioMediaInfo}
        selectedSubtitlesMediaInfo={selectedSubtitlesMediaInfo}
        seriesEpisodeText={seriesEpisodeText}
        seriesMetadata={seriesMetadata ?? null}
        startMargin={startMargin}
        subtitlesMediaInfo={subtitlesMediaInfo}
        tileType={this.getTileType()}
        timeshift={timeshift}
        title={title}
        totalDuration={totalDuration}
        userViewEndOffset={userViewEndOffset}
        userViewStartOffset={userViewStartOffset}
        videoQuality={this.getVideoQuality()}
        volume={volume}
      />
    );
  };

  renderBackwardPicto = (): React.Node => {
    const { skipping } = this.state;

    if (skipping === SkippingKind.BackwardLevel2) {
      return <PictoRewind60 />;
    }

    if (skipping === SkippingKind.BackwardLevel1) {
      return <PictoRewind30 />;
    }

    return <PictoRewind10 />;
  };

  renderForwardPicto = (): React.Node => {
    const { skipping } = this.state;

    if (skipping === SkippingKind.ForwardLevel2) {
      return <PictoForward60 />;
    }

    if (skipping === SkippingKind.ForwardLevel1) {
      return <PictoForward30 />;
    }

    return <PictoForward10 />;
  };

  renderPictos = (): React.Node => {
    const { isBuffering, isPlaying, skipping } = this.state;
    const { hidePlayPicto } = this;

    const backwardPicto = this.renderBackwardPicto();
    const forwardPicto = this.renderForwardPicto();
    const playOrPausePicto = isPlaying ? <PictoBigPlay className='play' /> : <PictoPause />;

    const backwardClass = clsx(
      'statusPictoBackground',
      (skipping === SkippingKind.BackwardLevel0 || skipping === SkippingKind.BackwardLevel1 || skipping === SkippingKind.BackwardLevel2) && 'skipBackward',
    );

    const forwardClass = clsx(
      'statusPictoBackground',
      (skipping === SkippingKind.ForwardLevel0 || skipping === SkippingKind.ForwardLevel1 || skipping === SkippingKind.ForwardLevel2) && 'skipForward',
    );

    return (
      <>
        <div className={backwardClass}>{backwardPicto}</div>
        {isBuffering || hidePlayPicto ? null : <div className={clsx('statusPictoBackground', isPlaying ? 'play' : 'pause')}>{playOrPausePicto}</div>}
        <div className={forwardClass}>{forwardPicto}</div>
      </>
    );
  };

  debugOverlayCloseHandler = () => {
    this.setState({ isDebugOverlayVisible: false });
  };

  render(): React.Node {
    const {
      maxBitrate,
      settings: { [Setting.GreenStreaming]: isBitrateLimited },
    } = this.props;

    const { isOverlayVisible } = this.state;
    const { keepAlive, videoPlayer } = this;

    return (
      <div className={clsx('playerView', videoPlayer?.name.toLowerCase(), isOverlayVisible && 'visibleCursor')}>
        <figure
          className='videoContainer'
          ref={(instance) => {
            this.videoContainer = instance;
          }}
        >
          <video
            className={clsx(isOverlayVisible && 'shifted')}
            onClick={this.handleOnClickOnVideo}
            onMouseMove={this.handleOnMouseMoveOnVideo}
            ref={(instance) => {
              this.videoElement = instance;
            }}
            src=''
          />
          {this.renderLoader()}
          {this.renderBlackOutOverlay()}
          {this.renderVideoController()}
          {this.renderPictos()}
        </figure>
        <ButtonBack
          className={clsx('backBar', isOverlayVisible && 'visible')}
          hasShaft
          hasText={false}
          onClick={this.handleBackOnClick}
          onMouseEnter={this.handleOnMouseEnterController}
          onMouseLeave={this.handleOnMouseLeaveController}
        />
        {renderDebugOverlay(this.state, isBitrateLimited, maxBitrate, keepAlive?.getState() ?? null, this.debugOverlayCloseHandler)}
      </div>
    );
  }
}

const mapStateToProps = (state: CombinedReducers): ReduxPlayerReducerStateType => {
  return {
    applicationName: state.appConfiguration.applicationName,
    authenticationToken: state.appRegistration.authenticationToken,
    channels: state.appConfiguration.deviceChannels,
    deviceSettings: state.appConfiguration.deviceSettings,
    isDebugModeEnabled: state.appConfiguration.isDebugModeEnabled,
    isMaxBitrateAllowed: state.appConfiguration.isMaxBitrateAllowed,
    maxBitrate: state.appConfiguration.maxBitrate,
    npvrRecordingsList: state.npvr.npvrRecordingsList,
    purchaseList: state.appRegistration.registration === RegistrationType.RegisteredAsGuest ? {} : (state.ui.purchaseList ?? {}),
    settings: state.ui.settings,
    state,
    streamPriorities: state.appConfiguration.playerStreamPriorities,
    useBOV2Api: state.appConfiguration.useBOV2Api,
    viewingHistory: state.ui.viewingHistory,
    viewingHistoryStatus: state.ui.viewingHistoryStatus,
  };
};

const mapDispatchToProps = (dispatch: Dispatch): ReduxPlayerDispatchToPropsType => {
  return {
    closeConfirmation: () => dispatch(hideModal()),

    localCreateSvodStreamFromId: (distributorId: string, vtiId: number, signal?: AbortSignal): Promise<any> => dispatch(purchaseAndCreate(distributorId, vtiId, '', signal)),

    localCreateVodStreamFromId: (distributorId: string, vtiId: number, signal?: AbortSignal): Promise<any> => dispatch(sendBOStreamCreateRequest(distributorId, vtiId, true, signal)),

    localGetImageUrl: (assetId: string, width: number, height: number, luminosity?: LuminosityType, signal?: AbortSignal): Promise<any> =>
      dispatch(
        getImageUrl(
          {
            assetId,
            height,
            luminosity,
            width,
          },
          signal,
        ),
      ),

    localSendNtgEntitlementGetRequest: (customData: string, service: string, url: string, channelId: string, signal?: AbortSignal): Promise<any> =>
      dispatch(sendNtgEntitlementGetRequest(customData, service, url, channelId, signal)),

    localSendNtgEntitlementReleaseRequest: (customData: string, service: string, url: string, channelId: string, signal?: AbortSignal): Promise<any> =>
      dispatch(sendNtgEntitlementReleaseRequest(customData, service, url, channelId, signal)),

    localSendV8LocationEpgRequest: (startTime: number, range: number, channelIds: Array<string>, signal?: AbortSignal): Promise<any> =>
      dispatch(sendV8LocationEpgRequest(startTime, range, undefined, channelIds, signal)),

    localSendV8MetadataLocationRequest: (assetId: string, signal?: AbortSignal): Promise<any> => dispatch(sendV8MetadataLocationRequest(assetId, signal)),

    localSendV8MetadataRequest: (assetId: string, type: MetadataKind, signal?: AbortSignal): Promise<any> => dispatch(sendV8MetadataRequest(assetId, type, signal)),

    localSendV8RecordingsMetadataRequest: (recordId: string, signal?: AbortSignal): Promise<any> => dispatch(sendV8RecordingsMetadataRequest(recordId, signal)),

    localSendVideofuturStreamCreateRequest: (distributorId: string, vtiId: number, licence: boolean, signal?: AbortSignal): Promise<any> =>
      dispatch(sendBOStreamCreateRequest(distributorId, vtiId, licence, signal)),

    localSendVideofuturStreamStartRequest: (distributorId: string, streamId: number, signal?: AbortSignal): Promise<any> => dispatch(sendBOStreamStartRequest(distributorId, streamId, signal)),

    localSendVideofuturStreamStopRequest: (distributorId: string, streamId: number, bookmark?: string, signal?: AbortSignal, keepAlive?: boolean): Promise<any> =>
      dispatch(sendBOStreamStopRequest(distributorId, streamId, bookmark, signal, keepAlive)),

    localShowConfirmation: (data: CONFIRMATION_DATA_MODAL_TYPE) => dispatch(showConfirmationModal(data)),

    localStopOpenStreams: (distributorId: string, signal?: AbortSignal): Promise<any> => dispatch(stopOpenStreams(distributorId, signal)),

    localUpdateSetting: (setting?: Setting, value: SettingValueType) => dispatch(updateSetting(setting, value)),

    localUpdateViewingHistory: (item: NETGEM_API_VIEWINGHISTORY_ITEM, signal?: AbortSignal): Promise<any> => dispatch(updateViewingHistory(item, signal)),
  };
};

const Player: React.ComponentType<PlayerPropType> = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PlayerView);

export default Player;
